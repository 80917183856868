import { Col, Row } from "antd";
import React from "react";
import ChartCard from "../../../components/ChartCard";
import {  useSelector } from "react-redux";
import PathRed from "assets/images/Path38055.png";
import GraphPurple from "assets/images/Group41973.png";
import PathGreen from "assets/images/Path38119.png";
import { BiLibrary } from "react-icons/bi";
const ChartCourse = () => {
 
  const curdProps = useSelector((state) => state.CurdR);
  const { chartRecord } = curdProps;
 

  return (
    <div>
      <Row className="gx-mb-2 gx-h-50">
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            className="gx-d-flex gx-align-items-center"
            bgColor="#EFD7FB"
            title={"course.Card1Title"}
            icon={<BiLibrary size={29} fill="#A212E8" />}
            count={(chartRecord && chartRecord.length && chartRecord[0].totalCourses) || 0}
            graph={<img src={GraphPurple} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"course.Card2Title"}
            icon={<BiLibrary size={29} fill="#02BC77" />}
            count={(chartRecord && chartRecord.length &&  chartRecord[0].onlineCourses) || 0}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#FADADC"
            title={"course.Card3Title"}
            icon={<BiLibrary size={29} fill="#F5222D" />}
            count={(chartRecord && chartRecord.length &&  chartRecord[0].offlineCourses) || 0}
            graph={<img src={PathRed} alt="graph" />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChartCourse;
