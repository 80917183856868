import React, { useState } from "react";
import RequestList from "components/Table/PageNationTable";
import ReservationChart from "./components/ReservationCharts";
import Widget from "components/Widget";
import TableHeader from "./components/TableHeader";
import Titleform from "../Request/components/profileForm";
import moment from "moment";
import ActionDropdown from "components/Table/Actions";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import { renderStatus } from "utils/commonUseFunction";
import Layout from "components/Layout";
import { intlPlaceholder } from "util/intlPlaceholder";

const Reservation = () => {
  const [filterValue, setFilterValue] = useState({});

  const renderActions = (record) => {
    record.meetingDate = record && moment(record.meetingDate);
    return (
      <div
        style={{ gap: "5px" }}
        className="gx-d-flex gx-flex-column popoverContent"
      >
              {record.status === "pending" && (
        <DeleteConfirm
        type="deleteMeetingRequest" 
        recordName="reservationList"
        selectedItem={record}
        apiRequest="DeleteTableRecord"
        buttonName={intlPlaceholder("noticeBoard.buttonName")}
        oKText={intlPlaceholder("noticeBoard.oKText")}
        title={intlPlaceholder("noticeBoard.deleteTitle")}
        content={intlPlaceholder("noticeBoard.deleteContent")}
        Icon={intlPlaceholder("noticeBoard.actionDeleteName")}
      />
        )}
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("request.ID"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.meetingId || "----"}</span>
      ),
      key: "requestId",
    },
    {
      title: intlPlaceholder("request.day"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.reservationDay || "----"}
        </span>
      ),
      key: "day",
    },
    {
      title: "Reservation With",
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.reservationWithName || "----"}
        </span>
      ),
      key: "ReservationWith",
    },
    {
      title: intlPlaceholder("request.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.meetingDate).format("MM-DD-YYYY") || "----"}
        </span>
      ),
      key: "date",
    },
    {
      title: intlPlaceholder("request.time"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.meetingTime || "----"}
        </span>
      ),
      key: "time",
    },
    {
      title: intlPlaceholder("instructor.Status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status)}
        </span>
      ),
      key: "status",
    },
    {
      title: intlPlaceholder("request.meetingAgenda"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.meetingAgenda || "----"}
        </span>
      ),
      key: "meetingAgenda",
    },
    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "50px",
    },
  ];
  //  const data = [
  //   {
  //     Id: "001",
  //     day:"Wed",
  //     date:"01Sep2022",
  //     time:"08:00AM-09:00Am",
  //     status:"pending",
  //     meetingAgenda:"Attendence issue",

  //   },
  // ];

  return (
    <Layout>
      <ReservationChart />
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <RequestList
          apiName="getReservationList"
          recordName="reservationList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      <Titleform />
    </Layout>
  );
};
export default Reservation;
