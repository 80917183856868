import React from "react";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import AuthLeftSide from "../AuthLeftSide/AuthLeftSide";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Form, Input, Button, message } from "antd";

const { Title } = Typography;
const Index = () => {
  const history = useHistory();
  const authProps = useSelector((state) => state.auth);
  const { ForSuccess, ShowForgotLoader, ForError } = authProps;
  const dispatch = useDispatch();
  const onFinish = (values) => {
    values.email = values.email.toLowerCase();
    dispatch(
      DataRequestAction(
        "POST",
        "forgetPassword",
        "forgetPassword",
        values,
        "forgot",
        "",
        ""
      )
    );
  };

  if (ForSuccess) {
    message.info("Email is sended in your inbox");
    history.push("/login");
    dispatch({
      type: "update_auth",
    });
  }
  if (ForError) {
    message.error("Some Error Occur Try Later");

    dispatch({
      type: "update_auth",
    });
  }
  const redirectToLogin = () => {
    history.push("/login");
  };
  return (
    <AuthLeftSide>
      <div
        className="gx-h-100 gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center"
        style={{ gap: "8%" }}
      >
        <div className="gx-text-center">
          <Title
            className="gx-font-sans-bold gx-mb-0"
            level={2}
            style={{ color: "#FFFFFF" }}
          >
            {intlPlaceholder("forget.pageTitle")}
          </Title>
          <Title
            className="gx-font-sans-regular gx-mt-0 gx-px-4 gx-py-3"
            level={5}
            style={{ color: "#FFFFFF", gap: "10px" }}
          >
            <span className="gx-text-white">
              {" "}
              {intlPlaceholder("Forgot.content")}
            </span>
          </Title>
        </div>
        <Form
          layout="vertical"
          name="normal_login"
          className="login-form-inputs"
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            label={
              <span className="gx-text-white">
                {intlPlaceholder("forget.inputLabel")}
              </span>
            }
            rules={[
              {
                required: true,
                message: intlPlaceholder("forget.email_message"),
              },
              {
                type: "email",
                message: "Enter Valid Email",
              },
            ]}
          >
            <Input
              className="gx-text-lowercase"
              placeholder={intlPlaceholder("forget.email_placeholder")}
            />
          </Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            style={{ height: "50px", fontSize: "16px" }}
            className="gx-w-100 gx-mt-4 gx-gradient-button gx-py-0 gx-font-sans-bold"
            loading={ShowForgotLoader}
            disabled={ShowForgotLoader}
          >
            {intlPlaceholder("forget.sentBtn")}
          </Button>
          <p className="gx-font-sans-regular gx-d-flex gx-align-items-center  gx-justify-content-end gx-font-16">
            {intlPlaceholder("forgetPassword.messageplaceholder")}
            <span
              onClick={() => redirectToLogin()}
              style={{
                cursor: "pointer",
                color: "#FFFFFF",
                marginLeft: "10px",
                fontSize: "18px",
                textDecoration: "underline",
              }}
            >
              Login
            </span>
          </p>
        </Form>
      </div>
    </AuthLeftSide>
  );
};

export default Index;
