import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import ReactQuil from "../../../../components/ReactQuil";

const NoticeForm = () => {
  const Role = [
    {
      value: "generalNotice",
      label: "General Notice",
    },
    {
      value: "courseNotice",
      label: "Course Notice",
    },
  ];

  const studentProps = useSelector((state) => state.CurdR);
  const [message, setMessage] = useState("");
  const { courseTitles } = studentProps;
  const [showField, setShowField] = useState(false);
  const onChange = (e) => {
    if (e === "courseNotice") {
      setShowField(true);
    } else {
      setShowField(false);
    }
  };

  return (
    <div className="gx-px-4">
      <Form.Item
        label={intlPlaceholder("notice.title")}
        name="noticeTitle"
        rules={[
          {
            required: true,
            message: intlPlaceholder("quiz.message"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={intlPlaceholder("notice.select")}
        name="noticeType"
        rules={[
          {
            required: true,
            message: intlPlaceholder("quiz.message"),
          },
        ]}
      >
        <Select onChange={onChange}>
          {Role.map((Info) => {
            return (
              <Select.Option value={Info.value}>{Info.label}</Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      {showField && (
        <Form.Item
          name="assignedCourseId"
          label={intlPlaceholder("quiz.course")}
          rules={[
            {
              required: true,
              message: intlPlaceholder("quiz.message"),
            },
          ]}
        >
          <Select showSearch placeholder="Select Course">
            {courseTitles &&
              courseTitles.map((course) => {
                return (
                  <Select.Option value={course._id}>
                    {course.courseTitle}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      )}
      <ReactQuil
        Form={Form}
        name="message"
        labelName={intlPlaceholder("notice.textarea")}
        setValue={setMessage}
        value={message}
      />
    </div>
  );
};

export default NoticeForm;
