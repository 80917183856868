import React from "react";
import { Empty } from "antd";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
const EnrolledStudent = ({ enrolledStudentCourseWise,totalNumberOfStudents}) => {
  console.log("=====enrolledStudentCourseWise", enrolledStudentCourseWise);

  // const data = [
  //   { name: "Linux", value: 23 },
  //   { name: "Web Development", value: 10 },
  //   { name: "Web Designing", value: 20 },
  //   { name: "Networking", value: 25 },
  //   { name: "AutoCAD", value: 8 },
  //   { name: "PHP", value: 12 },
  // ];

  const COLORS = [
    "#EE834F",
    "#E55472",
    "#267CF9",
    "#31A9F3",
    "#5EC22D",
    "#F1B332",
    "#EE834F",
    "#E55472",
    "#267CF9",
    "#31A9F3",
    "#5EC22D",
    "#F1B332",
  ];

  const renderSubject = () => {
    return (
      enrolledStudentCourseWise &&
      enrolledStudentCourseWise.map((item, index) => {
        item.color = COLORS[index] ? COLORS[index] : "#F1B332";
        return (
          <div className="gx-d-none gx-d-lg-flex gx-align-items-start">
            {" "}
            <span
              className="gx-mt-1"
              style={{ width: "17px", height: "11px", background: item.color }}
            ></span>{" "}
            <p  style={{ whiteSpace: "nowrap" }} className="gx-pl-2 gx-text-capitalize">
              {item.courseTitle}
            </p>
          </div>
        );
      })
    );
  };
  return (
    <>
      {enrolledStudentCourseWise && enrolledStudentCourseWise.length > 0 ? (
        <div className=" gx-d-flex gx-flex-row gx-align-items-center gx-justify-content-center gx-justify-content-md-between ">
          <div className="gx-px-0 gx-d-flex   gx-flex-column  ">
            {renderSubject()}
          </div>
          <ResponsiveContainer width="52%" height={138}>
            <PieChart className="gx-px-0">
              <Tooltip />
              <text
                x="49%"
                className="h4"
                y="45%"
                textAnchor="middle"
                dominantBaseline="middle"
              >
                {totalNumberOfStudents}
              </text>
              <text
                x="50%"
                className="h6"
                style={{ color: "red" }}
                y="63%"
                textAnchor="middle"
                dominantBaseline="middle"
              >
                Total Students
              </text>
              <Pie
                data={enrolledStudentCourseWise}
                dataKey="noOfStudentEnrolled"
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={58}
                fill="#8884d8"
              >
                {enrolledStudentCourseWise.map((entry, index) => {
                  return <Cell key={index} fill={entry.color} />;
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="gx-d-flex gx-align-items-center gx-justify-content-center gx-h-75">
          {" "}
          <Empty />
        </div>
      )}
    </>
  );
};

export default EnrolledStudent;
