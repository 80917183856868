import React from "react";
import { Select, Form, DatePicker, Input, Spin, message } from "antd";
import { DataGetAction } from "redux/actions/CommonHttp";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
const { TextArea } = Input;

const ReservationForm = ({ id, setId }) => {
  const dispatch = useDispatch();
  const curdProps = useSelector((state) => state.CurdR);
  const { timeList, studentSuccess, timeListMessage, timeListTrue } = curdProps;
  const createdBy = timeList && timeList.createdBy;
  const TimeDetails = timeList && timeList.meetingTime;
  const onChange = (date) => {
    if (date) {
      dispatch(
        DataGetAction(
          "getAdminMeetingSlot",
          "FetchDataArray",
          { date: date._d },
          "StudentSpinner",
          "",
          ""
        )
      );
    }
  };

  if (!studentSuccess && timeListTrue) {
    message.info(timeListMessage.message);
    setId(createdBy);
    dispatch({
      type: "ResetAll_State",
    });
  }

  return (
    <div className="gx-px-5">
      <Form.Item
        name="meetingDate"
        label={intlPlaceholder("reservation.date")}
        rules={[
          {
            required: true,
            message: intlPlaceholder("reservationDate.message"),
          },
        ]}
      >
        <DatePicker
          format={"MM-DD-YYYY"}
          onChange={(e) => onChange(e)}
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          className="gx-w-100"
        />
      </Form.Item>

      {studentSuccess && studentSuccess ? (
        <Spin className="gx-d-flex gx-align-items-center gx-justify-content-center"></Spin>
      ) : (
        !studentSuccess &&
        studentSuccess !== undefined &&
        timeList &&
        timeList.meetingTime &&
        timeList.meetingTime.length > 0 && (
          <Form.Item
            name="meetingTime"
            label={intlPlaceholder("reservation.time")}
            rules={[
              {
                required: true,
                message: intlPlaceholder("reservationTime.message"),
              },
            ]}
          >
            <Select placeholder={intlPlaceholder("ReservationSelect.time")}>
              {TimeDetails &&
                TimeDetails.map((Item) => {
                  return <Select.Option value={Item}>{Item}</Select.Option>;
                })}
            </Select>
          </Form.Item>
        )
      )}

      <Form.Item
        label={intlPlaceholder("reservation.textarea")}
        name="meetingAgenda"
        rules={[
          {
            required: true,
            message: intlPlaceholder("reservationTextArea.message"),
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </div>
  );
};

export default ReservationForm;
