import React from "react";
import { Button, Modal, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  modalTitle,
  submitButtonText,
  cancelButtonText,
  children,
  layout = "Horizontal",
  width,
  className,
  showFooter = true,
}) => {
  const [form] = Form.useForm();
  const CrudProps = useSelector((state) => state.CurdR);
  const { Loader, initialValues } = CrudProps;
  
  if (!visible) {
    form.resetFields();
  }

  if (initialValues) {
    form.setFieldsValue(initialValues);
  }

  const formItemLayout =
    layout === "horizontal"
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 18,
          },
        }
      : null;
  return (
    <Modal
      visible={visible}
      title={modalTitle}
      width={width}
      form={form}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      okText={submitButtonText}
      cancelText={cancelButtonText}
      className={className}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={Loader}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {});
      }}
    >
      <Form
        form={form}
        {...formItemLayout}
        layout={layout}
        name="form_in_modal"
      >
        {children}
      </Form>
    </Modal>
  );
};

const CollectionsPage = ({
  children,
  modalTitle,
  basicButtonTitle,
  submitButtonText = "Update",
  cancelButtonText = "Cancel",
  apiMethod = "PUT",
  editApiRequest = "EditTableRecord",
  visible,
  ModalType = "Hide_Modal",
  editApiMethod = "PUT",
  editApiName,
  width,
  className,
  apiLoader = "StartSpinner",
  buttonStyleClass,
  isShowButton = true,
  extraFieldName,
  extraFieldValue,
  secondExtraFieldName,
  secondExtraFieldValue,
  layout = "horizontal",
  ModalName,
  recordName,
}) => {
  const dispatch = useDispatch();
  const CrudProps = useSelector((state) => state.CurdR);
  const { Loader, initialValues } = CrudProps;
  const onCreate = (values) => {
    console.log("=============vlaues",values)
    if (values.email) {
      values.email = values.email.toLowerCase();
    }
    if (extraFieldName) {
      values[extraFieldName] = extraFieldValue;
    }
    if (secondExtraFieldName) {
      values[secondExtraFieldName] = secondExtraFieldValue;
    }
    if (initialValues) {
      values._id = initialValues._id;

      dispatch(
        DataRequestAction(
          editApiMethod,
          editApiName,
          editApiRequest,
          values,
          apiLoader,
          ModalName,
          recordName
        )
      );
    }
  };

  return (
    <div>
      {isShowButton && (
        <Button
          type="primary"
          className={`gx-gradient-button gx-pt-0 ${buttonStyleClass}`}
          onClick={() => {
            dispatch({
              type: ModalType,
              payload: null,
            });
          }}
        >
          {basicButtonTitle}
        </Button>
      )}
      <CollectionCreateForm
        modalTitle={modalTitle}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
        apiMethod={apiMethod}
        ModalType={ModalType}
        initialValues={initialValues}
        layout={layout}
        confirmLoading={Loader}
        apiLoaderName={apiLoader}
        visible={visible}
        width={width}
        className={className}
        onCreate={onCreate}
        onCancel={() => {
          dispatch({
            type: ModalType,
            payload: null,
            ModalName: ModalName,
            clear: true,
          });
        }}
      >
        {children}
      </CollectionCreateForm>
    </div>
  );
};

export default CollectionsPage;
