import React from "react";
import { Typography } from "antd";

const { Title } = Typography;
const TableHeader = () => {
  return (
    <div
      className="gx-d-flex gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-2">
     View Noticeboard
      </Title>

      <div className="gx-d-flex gx-align-items-center">
     
      </div>
    </div>
  );
};

export default TableHeader;
