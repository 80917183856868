import { Col, Row, Card } from "antd";
import React, { useEffect } from "react";
import ChartCard from "../ChartCard";
import PathYellow from "assets/images/Path.png";
import PathRed from "../../assets/images/GroupChart.png";
import PathBlue from "assets/images/Path38118.png";
import PathGreen from "assets/images/Path38119.png";
import { DataGetAction } from "redux/actions/CommonHttp";
import { FaUserGraduate } from "react-icons/fa";
import { FaThList } from "react-icons/fa";
import { FaMeetup } from "react-icons/fa";
import { BiLibrary } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import SendMoney from "./components/DashboardNotice";
import WelcomeCard from "./components/WelcomeCard";
import DashboardList from "./components/StudentList";
import DashBoardMettingCard from "./components/DashBoardMettingCard";
import EnrolledStudent from "./components/EnrolledStudent";

const Index = () => {
  const dispatch = useDispatch();
  const adminProps = useSelector((state) => state.CurdR);
  const { dashboardData } = adminProps;
  console.log("====dashboardData====", dashboardData);
  const fetchAdminDashboard = () => {
    dispatch(
      DataGetAction(
        "getInstructorDashboardData",
        "FetchRecord",
        "",
        "gettingAdminDashboard",
        "",
        "dashboardData"
      )
    );
  };
  useEffect(fetchAdminDashboard, []);
  const cardTitls = (title) => {
    return (
      <span
        style={{ fontSize: "20px", color: "#272D3B" }}
        className="h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
      >
        {title}
      </span>
    );
  };
  const history = useHistory();

  return (
    <div>
      <Row>
        <Col xl={17} lg={17} md={17} sm={17} xs={24}>
          <WelcomeCard
            waitingTickets={dashboardData && dashboardData.waitingTickets}
            pendingMeetingRequests={
              dashboardData && dashboardData.pendingMeetingRequests
            }
            openAssignmentsCreatedByInstructor={dashboardData && dashboardData.openAssignmentsCreatedByInstructor}
          />
        </Col>
        <Col xl={7} lg={7} md={7} sm={7} xs={24}>
          <DashBoardMettingCard
            upComingMeetings={dashboardData && dashboardData.instructorScheduleMeetings}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#F9DAE5"
            title={"Total Students"}
            icon={<FaUserGraduate size={29} fill="#FF2366" />}
            count={dashboardData && dashboardData.totalNumberOfStudents}
            graph={<img src={PathRed} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"Total Courses"}
            icon={<BiLibrary size={29} fill="#02BC77" />}
            count={dashboardData && dashboardData.myCourses}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#E0ECFE"
            title={"Schedule Meetings"}
            icon={<FaMeetup size={29} fill="#4791FF" />}
            count={dashboardData && dashboardData.scheduleMeetings}
            graph={<img src={PathBlue} alt="graph" />}
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            bgColor="#FFF9E1"
            title={"Quizzes"}
            icon={<FaThList size={29} fill="#FFD950" />}
            count={dashboardData && dashboardData.mySubjectQuizzes}
            graph={<img src={PathYellow} alt="graph" />}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={15} lg={15} md={15} sm={24} xs={24}>
          <SendMoney
            data={dashboardData && dashboardData.noticeBoardInfo}
            title="NoticeBoard"
            route={"noticeboard"}
          />
        </Col>

        <Col xl={9} lg={9} md={9} sm={24} xs={24}>
          <Card
            bodyStyle={{ height: "333px" }}
            title={
              <span style={{ fontSize: "16px", color: "#545454" }}>
                Enrolled Students{" "}
                <span style={{ fontSize: "14px", color: "#545454" }}>
                  (Course Wise)
                </span>
              </span>
            }
            className="gx-card"
          >
            <EnrolledStudent
              enrolledStudentCourseWise={
                dashboardData && dashboardData.enrolledStudentCourseWise
              }
              totalNumberOfStudents={dashboardData && dashboardData.totalNumberOfStudents}
            />
          </Card>
        </Col>
      </Row>
      <Card
        title={cardTitls("Student List")}
        className="gx-card h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
        extra={
          <span
            onClick={() => history.push("/student")}
            className="gx-d-none gx-d-sm-block gx-pointer gx-mb-0"
            style={{ color: "#A212E8", fontSize: "17px" }}
          >
            View All
          </span>
        }
      >
        <DashboardList
          StudentsList={dashboardData && dashboardData.studentList}
        />
      </Card>
    </div>
  );
};

export default Index;
