import React from "react";
import { Typography } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import AddReservation from "./AddReservation";
import DateRangPicker from "components/Filters/DateRangPicker";
const { Title } = Typography;

const TableHeader = () => {
  return (
    <div
    className="gx-d-flex gx-justify-content-between gx-mb-3 gx-align-items-center"
    style={{ flexWrap: "wrap" }}>
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-0">
        {intlPlaceholder("reservation.pageTittle")}
      </Title>
      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
         <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getReservationList"
          recordName="reservationList"
          width="217px"
        />
       <AddReservation/> 
     
      </div>
    </div>
     
  );
};

export default TableHeader;