import React from "react";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Input,Form,Select } from "antd";
import { useSelector } from "react-redux";
const SupportForm =()=>{
  const SupportProps = useSelector((state) => state.CurdR);
  const { courseTitles } = SupportProps;
   return(
    <div className="gx-px-4">  
      <Form.Item
        label={intlPlaceholder("support.courseName")}
        name="courseName"
        rules={[
          {
            required: true,
            message: intlPlaceholder("support.courseMessage"),
          },
        ]}
      >
        <Select showSearch  placeholder={intlPlaceholder("support.coursePlaceHolder")}>
              {courseTitles &&
                courseTitles.map((course) => {
                  return (
                    <Select.Option value={course.courseTitle}>
                      {course.courseTitle}
                    </Select.Option>
                  );
                })}
            </Select>
     
      </Form.Item>
     
          <Form.Item
            label={intlPlaceholder("support.description")}
            name="subject"
            rules={[
              {
                required: true,
                message: intlPlaceholder("Support.textAreamessage"),
              },
            ]}
          >
            <Input.TextArea rows={7} />
          </Form.Item>
    </div>
   )
}

export default  SupportForm;