import React from "react";
import { Button, Col, Empty, Row, Card } from "antd";
import { useHistory } from "react-router-dom";

const SendMoney = ({ title, data, route }) => {
  console.log("====data====", data);
  const history = useHistory();
  return (
    <Card
      className="gx-card"
      title={
        <span
          style={{ fontSize: "20px", color: "#272D3B" }}
          className="h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium"
        >
          {title}
        </span>
      }
      extra={
        <p
          style={{ color: "#A212E8", fontSize: "17px" }}
          className="gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
          onClick={() => history.push("/noticeboard/viewNoticeboard")}
        >
          View All
        </p>
      }
    >
      <div
        className="gx-table-responsive"
        style={{ height: "280px", overflowY: "auto", overflowX: "hidden" }}
      >
        {data && !data.length > 0 && (
          <div className="gx-d-flex gx-h-100 gx-align-items-center gx-justify-content-center">
            <Empty />
          </div>
        )}
        {data && data.length > 0 && (
          <>
            {data &&
              data.map((item) => (
                <>
                  <Row className="gx-w-100 gx-m-0">
                    <Col
                      xl={20}
                      lg={20}
                      md={20}
                      sm={24}
                      xs={24}
                      className="gx-d-flex gx-flex-column gx-pl-0"
                      style={{ color: "#B1B1B1" }}
                    >
                      <span
                        className="gx-font-sans-medium"
                        style={{ fontSize: "18px", color: "#535353" }}
                      >
                        {item.noticeTitle && item.noticeTitle.length > 40
                          ? item.noticeTitle.slice(0, 40) + "..."
                          : item.noticeTitle}
                      </span>
                      <span
                        style={{ fontSize: "16px" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            item.message && item.message.length > 130
                              ? item.message.slice(0, 120) + "..."
                              : item.message,
                        }}
                      ></span>
                    </Col>
                    <Col
                      xl={4}
                      lg={4}
                      md={4}
                      className="gx-d-md-flex gx-d-none gx-align-items-center gx-justify-content-end"
                    >
                      <Button
                        onClick={() => history.push("/noticeboard/viewNoticeboard")}
                        className="gx-mb-0 gx-gradient-button "
                        type="primary"
                      >
                        More Info
                      </Button>
                    </Col>
                  </Row>
                  <hr className="gx-my-3" />
                </>
              ))}
          </>
        )}
      </div>
    </Card>
  );
};
export default SendMoney;
