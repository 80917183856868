import React from "react";
import { message, Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

const { confirm } = Modal;
const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const DeleteConfirm = ({
  title,
  content,
  buttonName,
  selectedItem,
  type,
  apiRequest,
  Icon,
  method = "DELETE",
  oKText,
  customBtnClass,
  btnLabelSize = "20px",
  recordName,
}) => {
  const dispatch = useDispatch();

  const showDeleteConfirm = () => {
    confirm({
      title: title,
      icon: (
        <QuestionCircleOutlined
          className="gx-mt-2"
          style={{ fontSize: "220%" }}
        />
      ),
      content: content,
      okText: oKText || "Delete",
      okType: "danger",
      cancelText: "Cancel",
      confirmLoading: true,

      onOk() {
        return new Promise((resolve, reject) => {
          const token = localStorage.getItem("token");

          fetch(`${ROOT_URL}/${type}`, {
            method: method,
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: token,
              APIType: "web",
            }),
            body: JSON.stringify(selectedItem),
          })
            .then((response) => {
              console.log("====== response", response);
              response.json().then((response) => {
                console.log("====== response1", response);
                if (response.status === "ERROR") {
                  console.log("====apiRequest====", apiRequest,response);

                  message.error(response.message);

                  dispatch({
                    type: `${apiRequest}_FAILURE`,
                    payload: { message: response.message },
                  });
                  resolve();
                } else {
                  dispatch({
                    type: `${apiRequest}_SUCCESS`,
                    payload: response,
                    recordName: recordName,
                  });
                  resolve();
                }
              });
            })
            .catch((err) => {
              console.log("====== error ====", err);
              dispatch({
                type: `${apiRequest}_FAILURE`,
                payload: "Some Error Occur While. Deleting Record",
              });
              reject();
            });
        }).catch((error) => {
          console.log("====== error1 ====", error);
          dispatch({
            type: `${apiRequest}_FAILURE`,
            payload: "Some Error Occur While. Deleting Record",
          });
        });
      },
      onCancel() {},
    });
  };

  return (
    <div
      className={`gx-pointer deleteButton ${customBtnClass} `}
      style={{ fontSize: '14px', padding:'7px 10px' }}
      onClick={showDeleteConfirm}
    >
      {Icon ? Icon : buttonName}
    </div>
  );
};

export default DeleteConfirm;
