import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "assets/images/LogoT.png";
import smallLogo from "assets/images/vta.png";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  TAB_SIZE,
} from "constants/ThemeSetting";

const SidebarLogo = () => {
  const { width } = useSelector(({ settings }) => settings);
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header gx-justify-content-center">
      <Link to="/" className="gx-site-logo ">
        <div>
          <img src={logo} width={"180px"} alt="background" />
        </div>
      </Link>

      <Link to="/" className="gx-site-logo-small ">
        <div className="gx-text-white">  <img src={smallLogo} alt="background_Logo" /></div>
      </Link>
    </div>
  );
};

export default SidebarLogo;
