import React, { useState, useEffect } from "react";
import AssignmentList from "components/Table/PageNationTable";
import ActionDropdown from "components/Table/Actions";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import ShowModal from "components/Modal/ShowModal";
import EditAssignment from "./components/eidtAssignment";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import Cards from "./components/AssignmentCharts";
import ViewAssignments from "./components/viewAssignments";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
import { DataGetAction, DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";
import { renderStatus } from "../../utils/commonUseFunction";
import { renderSubstring } from "../../utils/commonUseFunction";
import TitleDetails from "./components/titleDetails";

const Quizzes = () => {
  const [filterValue, setFilterValue] = useState({});
  const dispatch = useDispatch();

  const fetchCourse = () => {
    dispatch(
      DataGetAction(
        "getAllCourses",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "courseTitles"
      )
    );
  };
  useEffect(fetchCourse, []);

  const setUserStatus = (_id, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "editAssignment",
        "EditTableRecord",
        { _id: _id, status: status },
        "StartSpinner",
        "",
        "assignmentList"
      )
    );
  };

  const renderActions = (record) => {
    record.dueDate = record && moment(record.dueDate);

    return (
      <div className="gx-d-flex gx-flex-column  popoverContent">
        {record.status === "open" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "close")}
          >
            Close
          </span>
        )}
        {record.status === "close" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "open")}
          >
            Open
          </span>
        )}
        {record.status === "open" && (
          <ShowModal
            modalName={"EDIT_Assignment_MODAL"}
            buttonName="Edit"
            record={record}
          />
        )}

        <DeleteConfirm
          type="deleteAssignment"
          recordName="assignmentList"
          selectedItem={record}
          apiRequest="DeleteTableRecord"
          buttonName={intlPlaceholder("quiz.buttonName")}
          oKText={intlPlaceholder("quiz.oKText")}
          title={intlPlaceholder("quiz.deleteTitle")}
          content={intlPlaceholder("quiz.deleteContent")}
          Icon={intlPlaceholder("quiz.actionDeleteName")}
        />
      </div>
    );
  };
  const columns = [
    {
      title: "ID",
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.assignmentId || "---"}
        </span>
      ),
      key: "ID",
    },
    {
      title: intlPlaceholder("quiz.title"),
      render: (record) => (
        <span className="gx-text-capitalize gx-link gx-text-blue">
        {
          <ShowModal modalName={"TitleDetail_Modal"} record={record}>
            {record.assignmentTitle  
              ? record.assignmentTitle.length > 10
                ? record.assignmentTitle.slice(0, 20) + "...."
                : record.assignmentTitle
              : " "}
          </ShowModal>
        }
      </span>
      ),
      key: "Title",
    },
    {
      title: "Course",
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderSubstring(record.courseTitle || "---", "50", "50")}
        </span>
      ),
      key: "Course",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}> {"Type"}</span>,
      render: (record) => (
        <span className="gx-text-capitalize gx-mr-3">
          {record.assignmentType || "---"}
        </span>
      ),
      key: "assignmentType",
    },
    {
      title: "Questions",
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.noOfQuestions || "---"}
        </span>
      ),
      key: "Questions",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}> {"Total Marks"} </span>,
      render: (record) => (
        <span className="gx-text-capitalize gx-pr-3">
          {record.totalMarks || "---"}
        </span>
      ),
      key: "totalMark",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}> {"Passing Marks"}</span>,
      render: (record) => (
        <span className="gx-text-capitalize gx-pr-3 ">
          {record.passingMarks || "---"}
        </span>
      ),
      key: "passMarks",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}> {"Created By"}</span>,
      render: (record) => (
        <span
          style={{ whiteSpace: "nowrap" }}
          className="gx-text-capitalize gx-pr-3 "
        >
          {record.createdBy || "---"}
        </span>
      ),
      key: "createdBy",
    },

    {
      title: <span style={{ whiteSpace: "nowrap" }}> {"Attachments"}</span>,
      render: (record) => (
        <span className="gx-text-capitalize gx-link gx-text-blue">
        {record.attachFileUrl.length>0 ?( <ShowModal
            modalName={"viewAssignments_Modal"}
            buttonName="Attachment"
            record={record}
          />):"----"}
        </span>
      ),
      key: "attachFile",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}> {"Due Date"}</span>,
      render: (record) => (
        <span
          style={{ whiteSpace: "nowrap" }}
          className="gx-text-capitalize gx-pr-3"
        >
          {moment(record.dueDate).format("MM-DD-YYYY") || "----"}
        </span>
      ),
      key: "submissionDate",
    },

    {
      title: "Status",
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status || "---")}
        </span>
      ),
      key: "Status",
    },
    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "50px",
    },
  ];
  return (
    <Layout>
      <Cards />
      <Widget>
        <TableHeader
          filterValue={filterValue}
          setFilterValue={setFilterValue}
        />
        <AssignmentList
          apiName="getAssignment"
          recordName="assignmentList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      <EditAssignment />
      <ViewAssignments />
      <TitleDetails />
    </Layout>
  );
};

export default Quizzes;
