import React, { useState,useEffect} from "react";
import FormModal from "components/Modal/FormModal";
import CourseForm from "./courseForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddCourse = () => {

  const [form] = Form.useForm();
  const { ADD_Course_MODAL } = useSelector((state) => state.CurdR);

  const [photoList, setPhotoState] = useState([]);
  useEffect(()=>{
    if(!ADD_Course_MODAL){
      setPhotoState([])
    }
  },[ADD_Course_MODAL])
  return (
    <FormModal
      addButtonName={intlPlaceholder("Course.addCourse")}
      modalTitle={intlPlaceholder("Course.addTitle")}
      submitButtonText={intlPlaceholder("Course.addbutton")}
      addApiName="courseCreatedByInstructor"
      ModalName="ADD_Course_MODAL"
      addApiRequest="AddTableRecord"
      visible={ADD_Course_MODAL}
      width={650}
      recordName={"courseList"}
      form={form}
      extraFieldName="courseImageUrl"
      extraFieldValue={photoList}
    >
      <CourseForm
        setPhotoState={setPhotoState}
        photoList={photoList}
        form={form}
      />
    </FormModal>
  );
};

export default AddCourse;
