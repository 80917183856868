const ROOT_URL = process.env.REACT_APP_ROOT_URL;
export const DataRequestAction = (
  method,
  apiName,
  requestType,
  data,
  Loading = "LOADING",
  ModalName = "Nothing",
  recordName,
  MessageInitial,
  MessageInitialError
) => {
  return function (dispatch) {
    RequestProgress(dispatch, `${Loading}_START`);

    const token = localStorage.getItem("token");
    try {
      fetch(`${ROOT_URL}/${apiName}`, {
        method: method,
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: token,
        }),
        body: JSON.stringify(data),
      })
        .then((response) => {
          response.json().then((response) => {
            if (response.status === "ERROR") {
              RequestFail(
                dispatch,
                requestType,
                response.message,
                "error",
                MessageInitialError,
                true
              );
            }
            if (response.status === "SUCCESS") {
              RequestSuccess(
                dispatch,
                requestType,
                response,
                data,
                recordName,
              );
              RequestModalClose(dispatch, ModalName);
            }
          });
        })
        .catch((err) => {
          RequestFail(
            dispatch,
            requestType,
            "",
            "error",
            MessageInitialError,
            true
          );
          RequestModalClose(dispatch, ModalName);
        });
    } catch (error) {
      RequestFail(
        dispatch,
        requestType,
        "",
        "error",
        MessageInitialError,
        true
      );
    }
  };
};
export const DataGetAction = (
  apiName,
  requestType,
  data,
  Loading = "Nothing",
  ModalName = "",
  recordName,
  loaderName
) => {
  return function (dispatch) {
    RequestProgress(dispatch, `${Loading}_START`, loaderName);
    const token = localStorage.getItem("token");

    

    let param = "";
    if (data) {
      param = `/${JSON.stringify(data)}`;
    }

    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
      APIType: "web",
    };

    try {
      fetch(`${ROOT_URL}/${apiName}${param}`, { headers })
        .then((response) => {
          RequestUnAuthorized(dispatch, response.status);

          if (response.status === 404) {
            RequestFail(dispatch, requestType, "Request Fail outer", false);
          } else {
            response.json().then((response) => {
              RequestSuccess(
                dispatch,
                requestType,
                response,
                data,
                recordName,
                loaderName,
                false
              );
              RequestModalClose(dispatch, ModalName);
            });
          }
        })
        .catch((err) => {
          RequestFail(
            dispatch,
            requestType,
            "Request Fail outer",
            err,
            loaderName,
            false
          );
          RequestModalClose(dispatch, ModalName);
        });
    } catch (error) {
      RequestFail(
        dispatch,
        requestType,
        "Request Fail",
        error,
        loaderName,
        false
      );
      RequestModalClose(dispatch, ModalName);
    }
  };
};
export const FormDataRequestAction = (
  type,
  requestType,
  data,
  Loading = "LOADING",
  ModalName,
  recordName
) => {
  return function (dispatch) {
    RequestProgress(dispatch, `${Loading}_START`);
    const token = localStorage.getItem("token");
    try {
      console.log("===== data.file =====");
      const dataObj = new FormData();
      let file = null;
      if (data.file && data.file[0].originFileObj) {
        file = data.file[0].originFileObj;
      }
      if (data.file) dataObj.append("file", file);

      dataObj.append(
        "data",
        JSON.stringify({
          categoryId: data.categoryId,
          pharmacyId: data.pharmacyId,
        })
      );

      fetch(`${ROOT_URL}/${type}`, {
        method: "POST",
        headers: new Headers({
          Authorization: token,
        }),
        body: dataObj,
      })
        .then((response) => {
          if (response.status === 401) {
            RequestUnAuthorized(dispatch, `showUnauthorized`);
          }
          response.json().then((response) => {
            if (response.status === 409) {
              RequestFail(dispatch, "AlreadyExist", response.message, "error");
            } else if (response.status === "ERROR") {
              RequestFail(dispatch, requestType, response.message, "error");
              // RequestModalClose(dispatch, ModalName)
            } else {
              RequestSuccess(dispatch, requestType, response, data, recordName);
              RequestModalClose(dispatch, ModalName);
            }
          });
        })
        .catch((err) => {
          RequestProgress(dispatch, `${Loading}_STOP`);
          RequestFail(dispatch, type, "", "error");
        });
    } catch (error) {
      RequestProgress(dispatch, `${Loading}_STOP`);
      RequestFail(dispatch, type, "", "error");
    }
  };
};
export const setInitUrl = (url) => {
  return {
    type: "INIT_URL",
    payload: url,
  };
};
export const logoutFn = () => {
  return {
    type: "logout_success",
  };
};
function RequestFail(
  dispatch,
  type,
  textMessage,
  error,
  MessageInitialError,
  showToast
) {
 
  dispatch({
    type: `${type}_FAILURE`,
    payload: textMessage,
    error,
  });
}
function RequestSuccess(
  dispatch,
  type,
  data,
  reqData,
  recordName,
 
) {
 

  dispatch({
    type: `${type}_SUCCESS`,
    payload: data,
    recordName: recordName,
    reqData,
  });
}
function RequestProgress(dispatch, type, loaderName) {
  dispatch({
    type: `${type}`,
    loaderName: loaderName,
  });
}
function RequestModalClose(dispatch, ModalName) {
  if (ModalName !== "Nothing") {
    dispatch({
      type: "Hide_Modal",
      payload: null,
      ModalName: ModalName,
    });
  }
}
function RequestUnAuthorized(dispatch, type) {
  dispatch({
    type: "UN_AUTHORIZED",
  });
}
