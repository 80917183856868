import React from "react";
import { Avatar } from "antd";
import dummy from "assets/images/dummyImage.jpeg";
import { useSelector } from "react-redux";
const UserInfo = ({ drawerStyle }) => {
  const settingsProps = useSelector((state) => state.auth);
  const { authUser } = settingsProps;
  const renderImage = () => {
    if (
      authUser &&
      authUser.profileImageUrl &&
      authUser.profileImageUrl.length > 0
    ) {
      if (authUser.profileImageUrl[0].imageUrl) {
        return (
          <Avatar
            style={{ width: "100%", height: "100%" }}
            src={authUser.profileImageUrl[0].imageUrl}
          />
        );
      } else {
        return (
          <Avatar
            style={{ width: "100%", height: "100%" }}
            src={authUser.profileImageUrl[0]}
          />
        );
      }
    } else {
      return <Avatar style={{ width: "100%", height: "100%" }} src={dummy} />;
    }
  };
  return (
    <>
      <div className="gx-d-flex gx-align-items-center gx-flex-column gx-justify-content-center gx-mt-3  gx-mb-3">
        {!drawerStyle && (
          <div
            className="gx-mb-4"
            style={{
              overflow: "hidden",
              borderRadius: "50%",
              border: "6px solid #D99FEB",
              width: "151px",
              height: "151px",
            }}
          >
            {renderImage()}
          </div>
        )}
        {drawerStyle && (
          <div
            className="gx-mb-4"
            style={{
              overflow: "hidden",
              borderRadius: "50%",
              border: "6px solid #D99FEB",
              width: "60px",
              height: "60px",
            }}
          >
            {renderImage()}
          </div>
        )}
        <span
          className="gx-font-sans-bold"
          style={{ color: "#545454", fontSize: "16px" }}
        >
          {(authUser && authUser.fullName) || ""}
        </span>
      </div>
    </>
  );
};

export default UserInfo;
