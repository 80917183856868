import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import moment from "moment";
import { useSelector } from "react-redux";

const TitleDetails = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { TitleDetail_Modal, initialValues } = curdProps;
  console.log("===initialValues===", initialValues);
  return (
    <>
      <SimpleModal
        width={530}
        modalFor="TitleDetail_Modal"
        visible={TitleDetail_Modal}
      >
        <h5 className="gx-font-sans-bold">
          {initialValues && initialValues.noticeTitle}
        </h5>

        <p className="gx-mt-0">
          Created by <span className="gx-font-sans-bold  gx-px-1 gx-text-capitalize">{initialValues && initialValues.createdBy}</span> 
          {initialValues &&
            moment(initialValues.createdAt).format("DD-MMM-YYYY")}
        </p>
        <p dangerouslySetInnerHTML={{ __html: initialValues &&  initialValues.message }}  />
      </SimpleModal>
    </>
  );
};

export default TitleDetails;
