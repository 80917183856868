import React from "react";
import AddCourse from "./addCourse";
import SearchBox from "components/Filters/SearchBox";
import { intlPlaceholder } from "util/intlPlaceholder";
import Title from "components/Title";
const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3 "
      style={{ flexWrap: "wrap" }}
    >
      <Title name={"Mycourse.pageTittle"} />
      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
        <SearchBox
          apiName="getCourse"
          recordName="courseList"
          requestType="FetchTableRecord"
          width={"218px"}
          placeholder={intlPlaceholder("course.searchBox")}
          customQuery={{ query: "search", key: "name" }}
          useSetFilterValue={true}
          setFilterValue={setFilterValue}
        />

        <AddCourse/>
      </div>
    </div>
  );
};

export default TableHeader;
