import { Card, Empty } from "antd";
import React from "react";
import { MdOutlineNextPlan } from "react-icons/md";
import { BsArrowUpRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const DashBoardMettingCard = ({ upComingMeetings }) => {
  const history = useHistory();

  const renderMeeting = () => {
    
    return (
      upComingMeetings &&
      upComingMeetings.map((item, index) => {
        let color = item.role==="admin" ? "#39D5CF" :"#ED457C";
        let Navigate=item.role==="admin" ? "/meeting/reservation" :"/meeting/request"; 
        return (
          <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
            <div
              className="gx-d-flex   gx-align-items-center "
              style={{ gap: "13px" }}
            >
              <i
                className="gx-d-flex gx-align-items-center gx-justify-content-center gx-mb-2"
                style={{
                  background: color,
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                }}
              >
                <MdOutlineNextPlan size={23} fill={"#FFFFFF"} />
              </i>

              <div>
                <span style={{gap:"10"}} className="gx-font-sans-bold gx-font-16 gx-text-capitalize">
                  {item.fullName} <span>{`(${item.role})`}</span>
                </span>
                <p className="gx-font-sans-regular">{item.meetingTime}</p>
              </div>
            </div>
            <div>
              <i
                onClick={() => history.push(Navigate )}
                className="gx-d-flex gx-align-items-center gx-justify-content-center gx-pointer"
                style={{
                  background: "#02CCF2",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                }}
              >
                <BsArrowUpRight size={20} fill={"#FFFFFF"} />
              </i>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <Card
      title={
        <span
          style={{
            fontSize: "24px",
            textTransform: "capitalize",
            color: "#272D3B",
          }}
        >
          Upcoming
        </span>
      }
      className="gx-card gx-py-0"
      bodyStyle={{ padding: "17px", height: "160px", borderRadius: "20px" }}
      extra={
        <span className="gx-d-none gx-d-sm-block gx-pointer gx-mb-0 gx-font-sans-regular">
          Today
        </span>
      }
    >
      {upComingMeetings && upComingMeetings.length > 0 ? (
        renderMeeting()
      ) : (
        <Empty />
      )}
    </Card>
  );
};

export default DashBoardMettingCard;
