import React from "react";
import { ImBooks } from "react-icons/im";
import { GoBell } from "react-icons/go";
import { MdDashboard, MdAssignment } from "react-icons/md";
import { AiFillDatabase } from "react-icons/ai";
import { FaUserGraduate, FaMeetup, FaHandsHelping } from "react-icons/fa";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { IoSettingsSharp } from "react-icons/io5";
const List = {
  instructor: [
    {
      title: "sidebarItem.dashboard",
      url: "/dashboard",
      icon: <MdDashboard size={18} />,
      key: "dashboard",
      top: "3px",
    },

    {
      title: "sidebarItem.Course",
      url: "/Course",
      icon: <ImBooks size={18} />,
      key: "course",
      top: "3px",
    },
    {
      title: "sidebarItem.Student",
      url: "/student",
      icon: <FaUserGraduate size={18} />,
      key: "student",
      top: "3px",
    },
    {
      title: "sidebarItem.Meeting",
      url: "",
      icon: <FaMeetup size={18} />,
      key: "meeting",
      top: "3px",
      subMenu: [
        {
          title: "sidebarItem.Reservation",
          url: "/meeting/reservation",

          key: "reservation",
          top: "3px",
        },
        {
          title: "sidebarItem.Request",
          url: "/meeting/request",

          key: "request",
          top: "3px",
        },
        {
          title: "sidebarItem.Settings",
          url: "/meeting/settings",

          key: "settings",
          top: "3px",
        },
      ],
    },
    {
      title: "sidebarItem.Quiz",
      url: "/quiz",
      icon: <AiFillDatabase size={18} />,
      key: "quiz",
      top: "3px",
    },
    {
      title: "sidebarItem.assignments",
      url: "/assignment",
      icon: <MdAssignment size={18} />,
      key: "assignment",
      top: "3px",
    },
    {
      title: "sidebarItem.Support",
      url: "/support",
      icon: <FaHandsHelping size={18} />,
      key: "support",
    },
    {
      title: "sidebarItem.Noticeboard",
      url: "",
      icon: <BsFillFileEarmarkTextFill size={18} />,
      key: "noticeboard",
      top: "3px",
      subMenu: [
        {
          title: "sidebarItem.Create",
          url: "/noticeboard/createNotices",
          key: "createNotices",
          top: "3px",
        },
        {
          title: "sidebarItem.viewNoticeboard",
          url: "/noticeboard/viewNoticeboard",
          key: "viewNoticeboard",
          top: "3px",
        },
      ],
    },

    {
      title: "sidebarItem.Notification",
      url: "/notification",
      icon: <GoBell size={18} />,
      key: "notification",
      top: "3px",
    },

    {
      title: "sidebarItem.Setting",
      url: "/setting",
      icon: <IoSettingsSharp size={18} />,
      key: "setting",
      top: "3px",
    },
  ],
};

export default List;
