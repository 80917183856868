import React from "react";
import ShowModal from "components/Modal/ShowModal";
import ActionDropdown from "components/Table/Actions";
import Image from "assets/images/student.jpeg";
import moment from "moment";
//import DeleteConfirm from "components/Modal/DeleteConfirm";
import DeleteStatus from "components/Modal/DeleteStatus";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Row, Col, Card, Switch, Rate, Empty, Tag, message } from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import {renderSubstring} from "../../../utils/commonUseFunction"
const CourseList = ({ courseList }) => {
  const dispatch = useDispatch();
  const CurdProps = useSelector((state) => state.CurdR);
  const { success, error } = CurdProps;

  if (success) {
    message.success(CurdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  if (error) {
    message.error(CurdProps.message);
    dispatch({
      type: "ResetAll_State",
    });
  }

  const setUserStatus = (_id, check, KeyObj) => {
    console.log("===check", check);
    const status =
      check === "online" ? "offline" : check === "offline" ? "online" : check;
    dispatch(
      DataRequestAction(
        "PUT",
        "editCourseByInstructor",
        "EditTableRecord",
        { _id: _id, [KeyObj]: status },
        "",
        "",
        "courseList"
      )
    );
  };

  const renderActions = (record) => {
    record.startedDate = record && moment(record.startedDate);
    return (
      <div
        style={{ gap: "5px" }}
        className="gx-d-flex gx-flex-column popoverContent"
      >
        {record.courseStatus === "active" && (
          <ShowModal
            modalName={"EDIT_Course_MODAL"}
            buttonName="Edit"
            record={record}
          />
        )}
        {record.courseStatus === "block" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "active", "courseStatus")}
          >
            Active
          </span>
        )}
        {record.courseStatus === "active" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "block", "courseStatus")}
          >
            Block
          </span>
        )}

        {/* <DeleteConfirm
          type="deleteCourse" 
          selectedItem={record}
          apiRequest="DeleteTableRecord"
          recordName="courseList"
          buttonName={intlPlaceholder("status.buttonName")}
          oKText={intlPlaceholder("status.oKText")}
          title={intlPlaceholder("status.deleteTitle")}
          content={intlPlaceholder("status.deleteContent")}
          Icon={intlPlaceholder("status.actionDeleteName")}
        /> */}
        
          <DeleteStatus
            id={record._id}
            Status="deleted"
            KeyObj={"courseStatus"}
            statusApiName="courseDeletedByInstructor"
            recordName="courseList"
            apiRequest="DeleteTableRecord"
            buttonName={intlPlaceholder("status.buttonName")}
            oKText={intlPlaceholder("status.oKText")}
            title={intlPlaceholder("status.deleteTitle")}
            content={intlPlaceholder("status.deleteContent")}
            Icon={intlPlaceholder("status.actionDeleteName")}
          />
  
      </div>
    );
  };

  return (
    <>
      {courseList && courseList.length > 0 ? (
        courseList.map((item) => {
          const disabled = item.courseStatus === "block" ? true : false;

          return (
            <Card
              bodyStyle={{
                padding: "0",
                boxShadow: "0px 0px 16px #5C5C5CCC",
                borderRadius: "8px",
              }}
              className="gx-course-card"
            >
              <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  xs={24}
                  sm={24}
                  className="gx-px-0"
                  style={{ position: "relative" }}
                >
                
                  <img
                    src={(item.courseImageUrl && item.courseImageUrl.length && item.courseImageUrl[0].imageUrl)|| Image}
                    alt=""
                    width={"100%"}
                    height={"250px"}
                  />
                  <div
                    className="gx-text-center"
                    style={{
                      position: "absolute",
                      top: 12,
                      right: 12,
                      width: "78px",
                      height: "24px",
                      backgroundColor: "#041649",
                      border: "1px solid #D2D2D280",
                      borderRadius: "50px 0px",
                    }}
                  >
                    <span
                      className="gx-text-white "
                      style={{ fontSize: "12px" }}
                    >
                      {item.status}
                    </span>
                  </div>
                </Col>
                <Col
                  xl={19}
                  lg={19}
                  md={19}
                  xs={24}
                  sm={24}
                  className="gx-pr-2 gx-pl-3 gx-pt-2 gx-pb-2"
                >
                  <div className="gx-d-flex  gx-align-items-center gx-justify-content-between  gx-mb-1">
                    <span
                     
                      className="gx-font-sans-medium gx-font-14 gx-text-capitalize"
                      style={{ color: "#A61FE7",whiteSpace: "nowrap"  }}
                    >
                        {renderSubstring(item.courseTitle,"50","50")}                
                    </span>
                    <div>
                      <span className="gx-pr-2 gx-font-sans-regular gx-font-14">
                        {item.status === "online"
                          ? "Online Class"
                          : "Offline Class"}
                      </span>{" "}
                      
                      <Switch
                        style={{ color: "#5EC42D" }}
                        onChange={() =>
                          setUserStatus(item._id, item.status, "status")
                        }
                        checked={
                          item.courseStatus === "active"
                            ? item.status === "online"
                              ? true
                              : false
                            : false
                        }
                        disabled={disabled}
                      />
                      <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                        <div></div>
                        <div>
                          <span
                            className="gx-font-sans-regular gx-d-md-none gx-d-block gx-font-14 gx-flex-end"
                            style={{ color: "#1A1A1A" }}
                          >
                            <ActionDropdown>
                              {renderActions(item)}
                            </ActionDropdown>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="gx-mb-3 gx-d-flex gx-flex-row">
                    <span>
                      <Rate value={item.courseRating} disabled={true} />
                    </span>
                    <span className="gx-ml-3 gx-text-center gx-text-white gx-mt-1">
                      <Tag color={"#5EC42D"}> {item.courseRating}</Tag>
                    </span>
                  </div>
                  <div>
                    <Tag
                      className="gx-text-capitalize"
                      color={item.courseStatus === "active" ? "#5EC42D" : "red"}
                    >
                      {" "}
                      {item.courseStatus}
                    </Tag>
                  </div>
                  <div className="gx-pt-3">
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={12} xs={12}>
                        <Row className="">
                          <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                            <span className="gx-font-sans-regular gx-font-14">
                              ID
                            </span>
                          </Col>

                          <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                            <span className="gx-font-sans-regular gx-font-14">
                              Course Title
                            </span>
                          </Col>
                          <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                            <span className="gx-font-sans-regular gx-font-14">
                              Course Code
                            </span>
                          </Col>
                          <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                            <span className="gx-font-sans-regular gx-font-14">
                              Duration
                            </span>
                          </Col>
                          <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                            <span className="gx-font-sans-regular gx-font-14">
                              Date
                            </span>
                          </Col>
                          <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                            <span
                              style={{ whiteSpace: "nowrap" }}
                              className="gx-font-sans-regular gx-font-14"
                            >
                              Max Students
                            </span>
                          </Col>
                          <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                            <span className="gx-font-sans-regular gx-font-14"></span>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xl={24}
                        lg={24}
                        md={24}
                        sm={12}
                        xs={12}
                        className="gx-pt-3"
                      >
                        <Row>
                          <Col xl={2} lg={2} md={2} xs={24} sm={24}>
                            <span
                              className="gx-font-sans-regular gx-font-14"
                              style={{ color: "#1A1A1A" }}
                            >
                              {item.courseId}
                            </span>
                          </Col>

                          <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                            <span
                              className="gx-font-sans-regular gx-font-14 gx-text-capitalize"
                              style={{ color: "#1A1A1A" }}
                            >
                              {" "}
                              {renderSubstring(item.courseTitle, "50","50")}
                            </span>
                          </Col>
                          <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                            {" "}
                            <span
                              className="gx-font-sans-regular gx-font-14"
                              style={{ color: "#1A1A1A" }}
                            >
                              {item.courseCode}
                            </span>
                          </Col>
                          <Col xl={3} lg={3} md={3} xs={24} sm={24}>
                            {" "}
                            <span
                              className="gx-font-sans-regular gx-font-14"
                              style={{ color: "#1A1A1A" }}
                            >
                              {renderSubstring(item.courseDuration,"50","50")}
                            </span>
                          </Col>
                          
                          <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                            <span
                              className="gx-font-sans-regular gx-font-14"
                              style={{ color: "#1A1A1A" }}
                            >
                              {moment(item.startedDate).format("MM-DD-YYYY")}
                            </span>
                          </Col>
                          <Col xl={4} lg={4} md={4} xs={24} sm={24}>
                            <span
                              className="gx-font-sans-regular gx-font-14 gx-text-center"
                              style={{ color: "#1A1A1A" }}
                            >
                              {item.maximumStudent}
                            </span>
                          </Col>
                          <Col
                            xl={3}
                            lg={3}
                            md={3}
                            xs={24}
                            sm={24}
                            className="gx-d-flex gx-justify-content-end"
                          >
                            <span
                              className="gx-font-sans-regular gx-d-md-block gx-d-none  gx-font-14"
                              style={{ color: "#1A1A1A" }}
                            >
                              <ActionDropdown>
                                {renderActions(item)}
                              </ActionDropdown>
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Card>
          );
        })
      ) : (
        <Empty />
      )}
    </>
  );
};

export default CourseList;
