import React, {useEffect, useState } from "react";
import FormModal from "components/Modal/FormModal";
import QuizzForm from "./quizForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddQuiz = () => {
  const [form] = Form.useForm();
  const { ADD_Quiz_MODAL } = useSelector((state) => state.CurdR);
  const [photoList, setPhotoState] = useState([]);
  const [id, setId] = useState("");

  useEffect(()=>{
    if(ADD_Quiz_MODAL){
      setPhotoState([])
    }
  },[ADD_Quiz_MODAL])


  console.log("====id", id);
  return (
    <FormModal
      addButtonName={intlPlaceholder("quiz.add")}
      modalTitle={intlPlaceholder("quiz.titlechange")}
      addApiName="quizAddedByInstructor"
      ModalName="ADD_Quiz_MODAL"
      layout="vertical"
      visible={ADD_Quiz_MODAL}
      width={700}
      recordName={"quizList"}
      form={form}
      extraFieldName="assignTo"
      extraFieldValue={id}
      secondExtraFieldName="attachFileUrl"
      secondExtraFieldValue={photoList}
    >
      <QuizzForm
        setPhotoState={setPhotoState}
        photoList={photoList}
        setId={setId}
        form={form}
      />
    </FormModal>
  );
};
export default AddQuiz;
