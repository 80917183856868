import React from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
// import axios from "axios";
Quill.register("modules/imageUploader", ImageUploader);

const ReactQuil = ({ Form, name, labelName, value, setValue }) => {


  console.log("==========quilname", name);
  function handleChange(value) {
    setValue(value);
  }

  var toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    [{ direction: "rtl" }],
    [{ script: "sub" }, { script: "super" }],
    ["clean"],
  ];
  
  return (
    <Form.Item name={`${name}`} label={labelName} initialValue="">
      <ReactQuill
       rows={7}
        modules={{
          toolbar: toolbarOptions,
          // imageUploader: {
          //   upload: async (file) => {
          //     const bodyFormData = new FormData();
          //     bodyFormData.append("image", file);
          //     const response = await axios({
          //       method: "post",
          //       url: "https://api.imgbb.com/1/upload?key=d36eb6591370ae7f9089d85875e56b22",
          //       data: bodyFormData,
          //       headers: {
          //         "Content-Type": "multipart/form-data",
          //       },
          //     });
          //     return response.data.data.url;
          //   },
          // },
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "imageBlot",
        ]}
        value={value || ""}
        onChange={() => handleChange()}
      />
    </Form.Item>
  );
};
export default ReactQuil;
