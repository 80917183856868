import React from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Form } from "antd";
import "moment-timezone";
import NoticeForm from "./noticeboardForm";
const EditNoticeboard = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Notice_MODAL, initialValues } = curdProps;

  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("Notice.editPageTittle")}
        editApiName="editNoticeBoard"
        recordName={"noticeBoardList"}
        ModalName="EDIT_Notice_MODAL"
        layout="vertical"
        visible={EDIT_Notice_MODAL}
        form={form}
        initialValues={initialValues}
        width={650}
        isShowButton={false}
      >
        <NoticeForm />
      </FormModal>
    </div>
  );
};

export default EditNoticeboard;
