import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import StudentForm from "./studentForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Form } from "antd";
const EditStudent = () => {
  const [form] = Form.useForm();

  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Student_MODAL, initialValues } = curdProps;

  const [photoList, setPhotoState] = useState([]);

  useEffect(() => {
    if (initialValues) {
      setPhotoState(initialValues.profileImageUrl)
    }
  }, [initialValues]); 


  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("Student.editPageTittle")}
        editApiName="editStudentByInstructor"
        ModalName="EDIT_Student_MODAL"
        visible={EDIT_Student_MODAL}
        initialValues={initialValues}
        isShowButton={false}
        width={650}
        recordName={"studentList"}
        form={form}
        extraFieldName="profileImageUrl"
        extraFieldValue={photoList}
      >
        <StudentForm setPhotoState={setPhotoState} photoList={photoList} />
      </FormModal>
    </div>
  );
};

export default EditStudent;
