import React from "react";
import { Select } from "antd";
import { DataGetAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";
const CallFilters = ({
  showAll,
  placeholder,
  options,
  ItemValue, 
  width = "160px",
  label,
  apiName,
  searchKey,
  isShowingAll,
  requestType="FetchTableRecord",
  className,
  defaultValue,
  recordName,
  customQuery = {},
  setFilterValue,
  useSetFilterValue = false,
}) => {
  const dispatch = useDispatch();
  const handleChange = (data) => {
    let query = { query: "search", key: searchKey, value: data };
    if (data === undefined) {
      query = { query: "search", key: searchKey, value: "" };
    }

    if (useSetFilterValue) {
      setFilterValue(query);
    }
    if (data === "all") {
      query.query = "all";
      if (showAll) {
        return showAll();
      }
    }
    dispatch(
      DataGetAction(
        apiName,
        requestType,
        { ...query, ...customQuery },
        "searching",
        "",
        recordName
      )
    );
  };

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="children"
      style={{ width: width }}
      placeholder={placeholder}
      onChange={(e) => {
        handleChange(e);
      }}
      defaultValue={defaultValue}
      className={className}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {isShowingAll && <Select.Option value="all">All</Select.Option>}
      {options &&
        options.map((Item) => {
          return (
            <Select.Option
              className="gx-text-capitalize"
              value={Item[`${ItemValue}`]}
            >
              {Item[`${label}`]}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export default CallFilters;
