import React from "react";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Avatar, Empty } from "antd";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import StudentList from "../../Table/List";
import phoneFormatter from "phone-formatter";
const DashboardList = ({ StudentsList }) => {
  const renderActions = () => {
    return console.log(renderActions);
  };
  const columns = [
    {
      title: intlPlaceholder("DashboardList.ID"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.studentId}</span>
      ),
      key: "Id",
    },
    {
      title: intlPlaceholder("student.FullName"),
      render: (record) => (
        <span className="gx-d-flex gx-align-items-center">
          <Avatar
            className="gx-mr-2"
            size={45}
            src={
              (record.profileImageUrl &&
                record.profileImageUrl.length &&
                record.profileImageUrl[0].imageUrl) ||
              ""
            }
            icon={!record.profileImageUrl[0] && <UserOutlined />}
          />
          <span> {record.fullName || "----"} </span>
        </span>
      ),
      key: "fullName",
    },
    {
      title: intlPlaceholder("student.DashboardListEmail"),
      render: (record) => (
        <span className="gx-text-lowercase">{record.email}</span>
      ),
      key: "email",
    },
    {
      title: intlPlaceholder("student.DashboardListPhoneNumber"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {phoneFormatter.format(record.phoneNumber, "(NNN) NNN-NNNN", {
            normalize: false,
          }) || "----"}
        </span>
      ),
      key: "phoneNumber",
    },

    {
      title: intlPlaceholder("student.DashboardListdate"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.date).format("DD-MMM-YYYY")}
        </span>
      ),
      key: "date",
    },
  ];

  return (
    <>
      {" "}
      {StudentsList && StudentsList.length > 0 ? (
        <StudentList
          columns={columns}
          scroll={true}
          pagination={true}
          tempData={StudentsList}
        />
      ) : (
        <div className="gx-d-flex gx-align-items-center gx-justify-content-center gx-h-75">
          {" "}
          <Empty />
        </div>
      )}
    </>
  );
};

export default DashboardList;
