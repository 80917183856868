import React, { useState, useEffect } from "react";
import SupportList from "components/Table/PageNationTable";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import { DataGetAction } from "redux/actions/CommonHttp";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
import { renderStatus } from "../../utils/commonUseFunction";
import { useHistory } from "react-router-dom";
import ChartSupport from "./components/ChartSupport";
import { useDispatch } from "react-redux";
const Support = () => {
  const history = useHistory();
  const [filterValue] = useState({});
  const dispatch = useDispatch();

  const fetchCourse = () => {
    dispatch(
      DataGetAction(
        "getAllCourses",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "courseTitles"
      )
    );
  };
  useEffect(fetchCourse, []);

  const columns = [
    {
      title: intlPlaceholder("record.id"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.supportId || "----"}</span>
      ),
      key: "id",
    },
    {
      title: intlPlaceholder("record.subject"),
      render: (record) => (
        <span
          onClick={() => history.push("/viewSupportSubject")}
          className="gx-text-capitalize gx-text-link gx-text-blue gx-pointer"
        >
          {record.subject
            ? record.subject.length > 20
              ? record.subject.slice(0, 20) + "...."
              : record.subject
            : ""}
        </span>
      ),
      key: "subject",
    },
    {
      title: intlPlaceholder("record.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.createdAt).format("MM-DD-YYYY") || "----"}
        </span>
      ),
      key: "date",
    },
    {
      title: intlPlaceholder("record.status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status)}
        </span>
      ),
      key: "status",
    },
  ];

  // const data = [
  //   {
  //     id: "1001",
  //     subject: "Lorem Ipsum is simply dummy text...",
  //     role: "Teacher",
  //     date: "12-Jul-2022 08:40",
  //     status: "active",
  //   },
  // ];

  return (
    <Layout>
      <ChartSupport />
      <Widget>
        <TableHeader />
        <SupportList
          apiName="getSupport"
          recordName="supportList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
    </Layout>
  );
};

export default Support;
