import React from "react";
import { Col, Row } from "antd";
import ChartCard from "components/ChartCard";
import PathRed from "assets/images/Path38055.png";
import GraphPurple from "assets/images/Group41973.png";
import PathGreen from "assets/images/Path38119.png";
import { FaRegFileAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
const NoticeboardChart = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { chartRecord } = curdProps;

  return (
    <div>
      <Row className="gx-mb-2 gx-h-50">
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#EFD7FB"
            title={"noticeboardCard1.Title"}
            count={(chartRecord && chartRecord.length && chartRecord[0].total) || 0}
            icon={<FaRegFileAlt size={29} fill="#A212E8" />}
            graph={<img src={GraphPurple} alt="graph" />}
          />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#D4F4E8"
            title={"noticeboardCard2.Title"}
            count={(chartRecord && chartRecord.length && chartRecord[0].adminNotices) || 0}
            icon={<FaRegFileAlt size={29} fill="#02BC77" />}
            graph={<img src={PathGreen} alt="graph" />}
          />
        </Col>

        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <ChartCard
            bgColor="#FADADC"
            title={"noticeboardCard3.Title"}
            icon={<FaRegFileAlt size={29} fill="#FF2366" />}
            count={(chartRecord && chartRecord.length && chartRecord[0].studentNotices) || 0}
            graph={<img src={PathRed} alt="graph" />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default NoticeboardChart;
