import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard/index";
import Quizze from "./Quizze";
import Support from "./Support";
import CreateNotices from "./noticeBoard/CreateNoticeboard"
import ViewNoticeboard from "./noticeBoard/ViewNoticeBoard"
import Setting from "./Setting";
import Student from "./Student";
import reservation from "./Meeting/Reservation";
import Request from "./Meeting/Request";
import Settings from "./Meeting/settings";
import Course from "./Courses";
import ViewStudent from "./Student/components/viewStudent";
import Notification from "./Notification";
import Assignment from "./Assignments";
import ViewSupportSubject from "./Support/components/viewSupportSubject";
import PageError from "./Pageerror";
const App = ({ match }) => (
  <Switch>
    <Route path={`${match.url}dashboard`} component={Dashboard} />
    
    <Route path={`${match.url}quiz`} component={Quizze} />
    <Route path={`${match.url}support`} component={Support} />
    <Route path={`${match.url}noticeboard/createNotices`} component={CreateNotices} />
    <Route path={`${match.url}noticeboard/viewNoticeboard`} component={ViewNoticeboard} />
    <Route path={`${match.url}assignment`} component={Assignment} />
    <Route path={`${match.url}student`} component={Student} />
    <Route path={`${match.url}course`} component={Course} />
    <Route path={`${match.url}meeting/request`} component={Request} />
    <Route path={`${match.url}meeting/settings`} component={Settings} />
    <Route path={`${match.url}meeting/reservation`} component={reservation} />
    <Route path={`${match.url}viewStudent`} component={ViewStudent} />
    <Route
      path={`${match.url}viewSupportSubject`}
      component={ViewSupportSubject}
    />
    <Route path={`${match.url}notification`} component={Notification} />
    <Route path={`${match.url}setting`} component={Setting} />
    <Route path={`${match.url}*`} component={PageError} />

  </Switch>
);

export default App;
