import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormModal from "components/Modal/EditFormModal";
import QuizForm from "./quizForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Form } from "antd";

const EditQuiz = () => {
  const [form] = Form.useForm();

  const [photoUpList, setPhotoUpState] = useState([]);

  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Quiz_MODAL, initialValues } = curdProps;



  useEffect(() => { 
    if (initialValues) { 
      setPhotoUpState(initialValues.attachFileUrl);
    }
  }, [initialValues]);


  const [id, setId] = useState("");

  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("Quiz.editPageTittle")}
        editApiName="editQuiz"
        recordName={"quizList"}
        ModalName="EDIT_Quiz_MODAL"
        layout="vertical"
        visible={EDIT_Quiz_MODAL}
        form={form}
        initialValues={initialValues && initialValues}
        width={650}
        isShowButton={false}
        extraFieldName="assignTo"
        extraFieldValue={id}
        secondExtraFieldName="attachFileUrl"
        secondExtraFieldValue={photoUpList}
      >
        <QuizForm
          setPhotoState={setPhotoUpState}
          photoList={photoUpList}
          setId={setId} 
        />
      </FormModal>
    </div>
  );
};

export default EditQuiz;
