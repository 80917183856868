import React from "react";
import Widget from "components/Widget";
import { Col, Row } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
const ChartCard = ({ iconColor, icon, count, graph, title, bgColor = "#FDDAE5" }) => {
  return (
    <Widget className="gx-card-full gx-p-3">
      <div className="gx-d-flex gx-align-items-center" style={{ gap: "12px" }}>
        <span
          className="gx-d-flex gx-align-items-center gx-justify-content-center"
          style={{
            background: bgColor,
            width: "60px",
            height: "50px",
            borderRadius: "10px",   
          }}
        >
          {icon}
        </span>
        <div className="gx-w-100">
          <span className="gx-font-sans-regular" style={{ fontSize: "16px"}}>
            {title ? intlPlaceholder(title) : "Total"}
          </span>
          <Row className="gx-m-0 gx-w-100">
            <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-px-0">
              <span
                className="gx-font-sans-medium"
                style={{ fontSize: "24px" }}
              >
                {count || 0}
              </span>
            </Col>
            <Col
              xl={16}
              lg={16}
              md={16}
              sm={16}
              xs={16}
              className="gx-d-flex gx-justify-content-end gx-px-0"
            >
              {graph}
            </Col>
          </Row>
        </div>
      </div>
    </Widget>
  );
};
export default ChartCard;













// //components/ChartCard
// import React from "react";
// import Widget from "components/Widget";
// import { UserOutlined } from "@ant-design/icons";
// const ChartCard = ({ count, desc, bgColor, borderColor }) => {
//   return (
//     // <Widget className="gx-card-full">
//     //   <div className="gx-actchart gx-px-3 gx-pt-3">
//     //     <div className="ant-row-flex">
//     //       <h3 className="gx-mb-0  gx-blue-font gx-font-sans-bold ">{count}</h3>
//     //     </div>
//     //     <p className="gx-mb-0 gx-fs-sm gx-text-grey">{desc}</p>
//     //   </div>
//     //   <ResponsiveContainer width="100%" height={75}>
//     //     <AreaChart
//     //       data={Data}
//     //       a
//     //       margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
//     //     >
//     //       <defs>
//     //         <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
//     //           <stop offset="5%" stopColor={bgColor} stopOpacity={1} />
//     //         </linearGradient>
//     //       </defs>
//     //       <Area
//     //         dataKey="price"
//     //         type="monotone"
//     //         strokeWidth={2}
//     //         stackId="2"
//     //         stroke={borderColor}
//     //         fill={bgColor}
//     //         fillOpacity={1}
//     //       />
//     //     </AreaChart>
//     //   </ResponsiveContainer>
//     // </Widget>

//     <Widget className="gx-card-full gx-p-3">
//       <div className="gx-d-flex gx-flex-row  gx-justify-content-between ">
//         <div
//           className="gx-d-flex gx-align-items-center gx-justify-content-center"
//           style={{
//             background: "#EFD7FB",
//             width: "48px",
//             height: "48px",
//             borderRadius: "10px",
//             boxShadow: "0px 0px 0px #00000047",
//           }}
//         >
//           <UserOutlined
//             style={{ fontSize: "180%", color: "#A212E8", opacity: 1 }}
//           />
//         </div>
//         <div className="gx-align-items-center">
//           <span className="gx-font-sans-regular ">
//             Total Courses
//           </span>
//           <p>10</p>
//         </div>
//         <div style={{ textDecoration: "wavy"}}>
       
//  </div>
//       </div>
//     </Widget>
//   );
// };

// export default ChartCard;
