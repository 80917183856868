import React, { useState } from "react";
import StudentList from "components/Table/PageNationTable";
import ActionDropdown from "components/Table/Actions";
import Layout from "components/Layout";
import ShowModal from "components/Modal/ShowModal";
import EditStudent from "./components/editStudent";
import ResetPassword from "./components/resetPassword";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import DeleteStatus from "components/Modal/DeleteStatus";
//import DeleteConfirm from "components/Modal/DeleteConfirm";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { renderStatus } from "../../utils/commonUseFunction";
import { renderSubstring } from "../../utils/commonUseFunction";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useDispatch } from "react-redux";
import moment from "moment";
import phoneFormatter from "phone-formatter";
import { useHistory } from "react-router-dom";
import ChartStudent from "./components/chartComponentStudent";

const Student = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterValue, setFilterValue] = useState({});

  const resendInvite = (record) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "reinviteStudent",
        "EditTableRecord",
        record,
        "StartSpinner", 
        "",
        "studentList"
      )
    );
  };
  const setUserStatus = (_id, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "editStudent",
        "EditTableRecord",
        { _id: _id, status: status },
        "StartSpinner",
        "",
        "studentList"
      )
    );
  };
  const viewStudent = (record) => {
    return history.push({
      pathname: "/viewStudent",
      state: { _id: record._id },
    });
  };

  const renderActions = (record) => {
    record.registrationDate = record && moment(record.registrationDate);
    record.dateOfBirth = record && moment(record.dateOfBirth);
    return (
      <div
        style={{ gap: "5px" }}
        className="gx-d-flex gx-flex-column popoverContent"
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            viewStudent(record);
          }}
        >
          View
        </span>
        {record.status === "active" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "block")}
          >
            Block
          </span>
        )}
        {(record.status === "block" || record.status === "delete") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "active")}
          >
            Active
          </span>
        )}
        {(record.status === "pending" ||
          record.status === "invited" ||
          record.status === "reinvited") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => resendInvite(record)}
          >
            ReInvite
          </span>
        )}
        {(record.status === "active" ||
          record.status === "invited" ||
          record.status === "reinvited") && (
          <ShowModal
            modalName={"EDIT_Student_MODAL"}
            buttonName="Edit"
            record={record}
          />
        )}
        {record.status === "active" && (
          <ShowModal
            modalName={"Reset_StudentPassword_Modal"}
            buttonName="Reset Password"
            record={record._id}
          />
        )}

       
          <DeleteStatus
            id={record._id}
            Status="deleted"
            statusApiName="deleteStudent"
            recordName="studentList" 
            apiRequest="DeleteTableRecord"
            buttonName={intlPlaceholder("status.buttonName")}
            oKText={intlPlaceholder("status.oKText")}
            title={intlPlaceholder("status.deleteTitle")}
            content={intlPlaceholder("status.deleteContent")}
            Icon={intlPlaceholder("status.actionDeleteName")}
          />
       
        {/* {
          <DeleteConfirm
            type="deleteStudent" 
            recordName="studentList"
            selectedItem={record}
            apiRequest="DeleteTableRecord"
            buttonName={intlPlaceholder("noticeBoard.buttonName")}
            oKText={intlPlaceholder("noticeBoard.oKText")}
            title={intlPlaceholder("noticeBoard.deleteTitle")}
            content={intlPlaceholder("noticeBoard.deleteContent")}
            Icon={intlPlaceholder("noticeBoard.actionDeleteName")}
          />
        } */}
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("Student.ID"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.studentId || "----"}</span>
      ),
      key: "studentId",
      width: "100px",
    },
    {
      title: intlPlaceholder("student.FullName"),
      render: (record) => (
        <span className="gx-d-flex gx-align-items-center">
          <Avatar
            className="gx-mr-2"
            size={45}
            src={(record.profileImageUrl && record.profileImageUrl.length && record.profileImageUrl[0].imageUrl) || ""}
            icon={!record.profileImageUrl[0] && <UserOutlined />}
          />
          <span
            className="gx-pointer gx-text-blue gx-text-capitalize gx-link"
            onClick={() => {
              viewStudent(record);
            }}
          >
            {" "}
            {renderSubstring(record.fullName || "----", "30","30")}{" "}
          </span>
        </span>
      ),
      key: "fullName",
    },
    {
      title: intlPlaceholder("student.Email"),
      render: (record) => <span>{record.email || "----"}</span>,
      key: "email",
    },
    {
      title: intlPlaceholder("student.PhoneNumber"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {phoneFormatter.format(record.phoneNumber, "(NNN) NNN-NNNN", {
            normalize: false,
          }) || "----"}
        </span>
      ),
      key: "phoneNumber",
    },
    {
      title: intlPlaceholder("student.Status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status) || "----"}
        </span>
      ),
      key: "status",
    },
    {
      title: intlPlaceholder("student.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.createdAt).format("DD-MM-YYYY") }
        </span>
      ),
      key: "date",
    },

    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "50px",
    },
  ];

 

  return (
    <Layout>
      <ChartStudent />
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <StudentList
          apiName="getStudentForInstructor"
          recordName="studentList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
      </Widget>
      <EditStudent />
      <ResetPassword />
    </Layout>
  );
};

export default Student;
