import React from "react";
import NoticeboardList from "components/Table/PageNationTable";
import TableHeader from "./components/TableHeader";
import Layout from "components/Layout";
import Widget from "components/Widget";
import moment from "moment";
import ShowModal from "components/Modal/ShowModal";
import TitleDetails from "./components/titleDetails";
import { intlPlaceholder } from "util/intlPlaceholder";

const Noticeboard = () => {
  const columns = [
    {
      title: intlPlaceholder("ID"),
      render: (record) => <span>{record.noticeBoardId || "----"}</span>,
      key: "id",
      width: "100px",
    },
    {
      title: intlPlaceholder("record.title"),
      render: (record) => (
        <span className="gx-text-capitalize gx-link gx-text-blue">
          {
            <ShowModal modalName={"TitleDetail_Modal"} record={record}>
              {record.noticeTitle
                ? record.noticeTitle.length > 20
                  ? record.noticeTitle.slice(0, 20) + "...."
                  : record.noticeTitle
                : ""}
            </ShowModal>
          }
        </span>
      ),
      key: "NoticeboardTitle",
    },
    // {
    //   title: intlPlaceholder("record.type"),
    //   render: (record) => (
    //     <span className="gx-text-capitalize">{record.noticeType || "----"}</span>
    //   ),
    //   key: "NoticeboardType",
    // },


    {
      title: intlPlaceholder("record.message"),
      render: (record) => (
        <span
          dangerouslySetInnerHTML={{
            __html: record.message
              ? record.message.length > 40
                ? record.message.slice(0, 30) + "...."
                : record.message
              : "",
          }}
          className="gx-text-capitalize gx-pointer"
        ></span>
      ),
      key: "NoticeboardMessage",
    },
    {
        title: intlPlaceholder("Date"),
        render: (record) => (
          <span className="gx-text-capitalize">{moment(record.createdAt).format("MM-DD-YYYY") || "----"}</span>
        ),
        key: "course",
      },
  ];

  // const data = [
  //   {
  //     title: "New Private Course Published",
  //    type: "Students and Instructors",
  //     message: "Lorem Ipsum is simply dummy text...",
  //     date: "12-07-2022",

  //   },
  //   {
  //     title: "New Private Course Published",
  //    type: "Students and Instructors",
  //     message: "Lorem Ipsum is simply dummy text...",
  //     date: "12-08-2022",

  //   },
  // ];

  return (
    <Layout>
      <Widget>
<TableHeader />
        <NoticeboardList
          apiName="viewNoticesForInstructor"
          recordName="viewNoticeBoardList"
          requestType="FetchRecord"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
      <TitleDetails />
    </Layout>
  );
};

export default Noticeboard;
