import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/FormModal";
import Assignment from "./AssignmentForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddQuiz = () => {
  const [form] = Form.useForm();
  const { ADD_Assignment_MODAL } = useSelector((state) => state.CurdR);

  const [photoList, setPhotoState] = useState([]);
  
  useEffect(() => {
    if (!ADD_Assignment_MODAL) {
      setPhotoState([]);
    }
  }, [ADD_Assignment_MODAL]);

  const [id, setId] = useState("");
  
  return (
    <FormModal
      addButtonName={intlPlaceholder("quiz.add")}
      modalTitle={intlPlaceholder("Add Assignment")}
      addApiName="assignmentAddedByInstructor"
      ModalName="ADD_Assignment_MODAL"
      visible={ADD_Assignment_MODAL}
      width={700}
      recordName={"assignmentList"}
      form={form}
      extraFieldName="assignTo"
      extraFieldValue={id}
      secondExtraFieldName="attachFileUrl"
      secondExtraFieldValue={photoList}
    >
      <Assignment
        id={id}
        setId={setId}
        setPhotoState={setPhotoState}
        photoList={photoList}
      />
    </FormModal>
  );
};
export default AddQuiz;
