import React, { useState, useEffect } from "react";
import QuizzesList from "components/Table/PageNationTable";
import ActionDropdown from "components/Table/Actions";
import Layout from "components/Layout";
import TableHeader from "./components/TableHeader";
import Widget from "components/Widget";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import EditQuiz from "./components/editQuiz";
import ViewQuizzes from "./components/viewQuizzes";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
import { DataGetAction, DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch } from "react-redux";
import { renderStatus } from "../../utils/commonUseFunction";
import ChartQuiz from "./components/ChartQuiz";
import TitleDetails from "./components/titleDetails";

const Quizzes = () => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState({});

  const fetchCourse = () => {
    dispatch(
      DataGetAction(
        "getAllCourses",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "courseTitles"
      )
    );
  };
  useEffect(fetchCourse, []);

  const setUserStatus = (_id, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "editQuiz",
        "EditTableRecord",
        { _id: _id, status: status },
        "StartSpinner",
        "",
        "quizList"
      )
    );
  };

  const renderActions = (record) => {
    record.quizDate = record && moment(record.quizDate);
    return (
      <div
        style={{ gap: "5px" }}
        className="gx-d-flex gx-flex-column popoverContent"
      >
        {record.status === "active" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "close")}
          >
            Close
          </span>
        )}
        {(record.status === "close" || record.status === "delete") && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setUserStatus(record._id, "active")}
          >
            Active
          </span>
        )}
        {record.status === "active" && (
          <ShowModal
            modalName={"EDIT_Quiz_MODAL"}
            buttonName="Edit"
            record={record}
          />
        )}

        <DeleteConfirm
          type="deleteQuiz"
          recordName="quizList"
          selectedItem={record}
          apiRequest="DeleteTableRecord"
          buttonName={intlPlaceholder("quiz.buttonName")}
          oKText={intlPlaceholder("quiz.oKText")}
          title={intlPlaceholder("quiz.deleteTitle")}
          content={intlPlaceholder("quiz.deleteContent")}
          Icon={intlPlaceholder("quiz.actionDeleteName")}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "ID",
      render: (record) => (
        <span className="gx-text-capitalize">{record.quizId || "----"}</span>
      ),
      key: "Title",
    },
    {
      title: intlPlaceholder("quiz.title"),
      render: (record) => (
        <span className="gx-text-capitalize gx-link gx-text-blue">
          {
            <ShowModal modalName={"TitleDetail_Modal"} record={record}>
              {record.quizTitle
                ? record.quizTitle.length > 10
                  ? record.quizTitle.slice(0, 20) + "...."
                  : record.quizTitle
                : " "}
            </ShowModal>
          }
        </span>
      ),
      key: "Title",
    },
    {
      title: intlPlaceholder("Course"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.courseTitle || "----"}
        </span>
      ),
      key: "course",
    },
    {
      title: intlPlaceholder("Questions"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.noOfQuestions || "----"}
        </span>
      ),
      key: "Questions",
    },
    {
      title: intlPlaceholder("quiz.time"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.quizTime || "----"}</span>
      ),
      key: "Time(min)",
    },
    {
      title: intlPlaceholder("Total Marks"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.totalMarks || "----"}
        </span>
      ),
      key: "totalMarks",
    },
    {
      title: intlPlaceholder("quiz.passmarks"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.passMarks || "----"}</span>
      ),
      key: "Pass Marks",
    },
    {
      title: intlPlaceholder("quiz.date"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {moment(record.quizDate).format("DD-MMM-YYYY") || "----"}
        </span>
      ),
      key: "Date",
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{"Attachments"}</span>,
      render: (record) => (
        <span className="gx-text-capitalize gx-link gx-text-blue">
          {record.attachFileUrl.length > 0 ? (
            <ShowModal
              modalName={"viewQuizzes_Modal"}
              buttonName="attachments"
              record={record}
            />
          ) : (
            "----"
          )}

          {/* <ViewQuizzes initialValue={record}/> */}
        </span>
      ),
      key: "attachFile",
    },
    {
      title: intlPlaceholder("quiz.status"),
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status)}
        </span>
      ),
      key: "Status",
    },

    {
      title: "",
      render: (record) => (
        <ActionDropdown>{renderActions(record)}</ActionDropdown>
      ),
      key: "action",
      width: "50px",
    },
  ];
  // const data = [
  //   {

  //     title: "Final Quiz Learn Linux in 5 Days",
  //     questions: "5",
  //     time: "5",
  //     totalmarks: "100",
  //     passmarks: "70",
  //     date: "11 July 2021",
  //     status:"block"
  //   },
  //   {

  //     title: "Mid Term Quiz Excel from Beginner to Advance",
  //     questions: "5",
  //     time: "5",
  //     totalmarks: "100",
  //     passmarks: "70",
  //     date: "11 July 2021",
  //     status:"block"
  //   },
  // ];

  return (
    <Layout>
      <ChartQuiz />
      <Widget>
        <TableHeader setFilterValue={setFilterValue} />
        <QuizzesList
          apiName="getQuiz"
          recordName="quizList"
          columns={columns}
          scroll={true}
          pagination={true}
          filterValue={filterValue}
        />
        <EditQuiz />
        <ViewQuizzes />
        <TitleDetails />
      </Widget>
    </Layout>
  );
};

export default Quizzes;
