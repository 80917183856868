import React from "react";
import { Typography } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
import DateRangPicker from "components/Filters/DateRangPicker";
import AddNoticeboard from "../components/AddNoticeboard";
import SelectFilter from "components/Filters/SelectFilter";
const { Title } = Typography;
const TableHeader = () => {
  return (
    <div
      className="gx-d-flex gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title level={4} className="gx-text-capitalize gx-font-sans-bold gx-mb-2">
        {intlPlaceholder("noticeboard.pageTittle")}
      </Title>

      <div
        className="gx-d-flex gx-align-items-centre"
        style={{ gap: "10px", flexWrap: "wrap" }}
      >
        <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getNoticeBoard"
          recordName="noticeBoardList"
          width="230px"
        />
        <SelectFilter
          apiName="getNoticeBoard"
          recordName="noticeBoardList"
          placeholder={"status"}
          searchKey="status"
          width={"150px"}
          isShowingAll={false}
          options={[
            { status: "generalNotice", label: "General Notice" },
            { status: "course Notice", label: "Course Notice" },
          ]}
          ItemValue="status"
          label="label"
        />

        <AddNoticeboard />
      </div>
    </div>
  );
};

export default TableHeader;
