import React from 'react'
import FormModal from 'components/Modal/FormModal'
import SupportForm from './supportForm'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
const AddSupport = () => {
    const [form] = Form.useForm()
    const { ADD_Support_MODAL } = useSelector((state) => state.CurdR)
    return (
        <FormModal
            addButtonName={intlPlaceholder('support.add')}
            modalTitle={intlPlaceholder('support.titlechange')}
            addApiName="createSupportTicket"
            ModalName="ADD_Support_MODAL"
            layout="vertical"
            visible={ADD_Support_MODAL}
            width={700}
            recordName={'supportList'}
            form={form}
        >
            <SupportForm />
        </FormModal>
    )
}
export default AddSupport;