import React, { useState } from "react";
import AvatarUploader from "components/AvatarUploader";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Input, Form, DatePicker } from "antd";
import ReactQuil from "../../../components/ReactQuil";

const CourseForm = ({ setPhotoState, photoList }) => {
  const [courseSyllabus, setCourseSyllabus] = useState("");
  const [courseBenefits, setCourseBenefits] = useState("");


  
  return (
    <div className="gx-px-5">
      <Form.Item
        label={intlPlaceholder("course.courseTitle")}
        name="courseTitle"
        rules={[
          {
            required: true,
            message: intlPlaceholder("course.courseTitlemessage"),
          },
        ]}
      >
        <Input placeholder={intlPlaceholder("course.courseTitleplaceholder")} />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("course.coursePrice")}
        name="coursePrice"
        rules={[
          {
            required: true,
            message: intlPlaceholder("course.coursePricemessage"),
          },
        ]}
      >
        <Input
          min={0}
          placeholder={intlPlaceholder("course.coursePricplaceholder")}
          type={"number"}
        />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("course.courseSummary")}
        name="courseSummary"
        rules={[
          {
            required: true,
            message: intlPlaceholder("course.courseSummarymessage"),
          },
        ]}
      >
        <Input
          placeholder={intlPlaceholder("course.courseSummaryplaceholder")}
        />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("course.courseDetail")}
        name="courseDetail"
        rules={[
          {
            required: true,
            message: intlPlaceholder("course.courseDetailmessage"),
          },
        ]}
      >
        <Input
          placeholder={intlPlaceholder("course.courseDetailplaceholder")}
        />
      </Form.Item>
      <Form.Item
        name="startedDate"
        label={intlPlaceholder("course.startingDate")}
      >
        <DatePicker
          format="MM-DD-YYYY"
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          className="gx-w-100"
        />
      </Form.Item>
      <Form.Item
        label={intlPlaceholder("course.courseDuration")}
        name="courseDuration"
      >
        <Input
          placeholder={intlPlaceholder("course.courseDurationplaceholder")}
        />
      </Form.Item>
 
      <Form.Item
        label={intlPlaceholder("course.maximunStudent")}
        name="maximumStudent"
        rules={[
          {
            required: true,
            message: intlPlaceholder("course.maximunStudentmessage"),
          },
        ]}
      >
        <Input
          min={0}
          type={"number"}
          placeholder={intlPlaceholder("course.maximunStudentplaceholder")}
        />
      </Form.Item>

      <Form.Item name="photo" label={intlPlaceholder("course.picture")}>
      <AvatarUploader
         length={1}
          limit={0}
          list={[".png",".jpeg",".jpg"]}
        photoList={photoList} setPhotoState={setPhotoState} />
      </Form.Item>

      <ReactQuil
        Form={Form}
        name="courseSyllabus"
        labelName={intlPlaceholder("course.courseSyllabus")}
        setValue={setCourseSyllabus}
        value={courseSyllabus}
      />

      <ReactQuil
        Form={Form}
        name="courseBenefit"
        labelName={intlPlaceholder("course.courseBenefit")}
        setValue={setCourseBenefits}
        value={courseBenefits}
      />
    </div>
  );
};

export default CourseForm;
