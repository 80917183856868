import React from "react";

import { intlPlaceholder } from "util/intlPlaceholder";
import SelectFilter from "components/Filters/SelectFilter";
import DateRangPicker from "components/Filters/DateRangPicker";
import AddSupport from "./AddSupport";
import Title from "components/Title";

const TableHeader = ({ setFilterValue }) => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title name={"support.pageTittle"} />

      <div className="gx-d-flex" style={{ gap: "10px", flexWrap: "wrap" }}>
        <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getSupport"
          recordName="supportList"
          width="230px"
        />
        <SelectFilter
          apiName="getSupport"
          recordName="supportList"
          placeholder={intlPlaceholder("support.status")}
          searchKey="status"
          width={"200px"}
          isShowingAll={false}
          options={[
            { status: "resolved", label: "Resolved" },
            { status: "waiting", label: "Waiting" },
          ]}
          ItemValue="status"
          label="label"
        />
        <AddSupport />
      </div> 
    </div>
  );
};

export default TableHeader;
