import {
  addItemInRecord,
  recordAfterUpdate,
  recordAfterDeleted,
} from "./CommonFn";

const initialState = {
  Records: [],
  success: false,
  error: false,
  message: "",
  Loader: false,
  initialValues: null,
  savingSpinner: false,
  fetchSuccess: false,
  DeleteError: false,
  FetchingSpinner: false,
  bulkImportSuccess: false,
  studentSuccess: false,
  timeListMessage: "",
  addCustom:false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FetchDataArray_SUCCESS": {
      return {
        ...state,
        timeList: action.payload.Record,
        timeListMessage: action.payload,
        timeListTrue: true,
        studentSuccess: false,
      };
    }
    case "Show_Modal": {
      return {
        ...state,
        [action.ModalName]: true,
        initialValues: action.payload,
      };
    }
    case "bulkImportProduct_SUCCESS": {
      return {
        ...state,
        Loader: false,
        bulkImportSuccess: true,
        FetchingSpinner: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Successfully",
        savingSpinner: false,
      };
    }
    case "Hide_Modal": {
      return {
        ...state,
        [action.ModalName]: false,
        initialValues: action.ModalName ? null : state.initialValues,
      };
    }

    case "StudentSpinner_START": {
      return {
        ...state,
        studentSuccess: true,
      };
    }
    case "StartSpinner_START": {
      return {
        ...state,
        Loader: true,
        savingSpinner: false,
        FetchingSpinner: true,
      };
    }
    case "StartSavingSpinner_START": {
      return {
        ...state,
        Loader: true,
        savingSpinner: true,
      };
    }

    case "FetchSingleRecord_SUCCESS":
      return {
        ...state,
        SingleRecord: action.payload.Record,
        Loader: false,
      };

    case "FetchSingleRecord_FAILURE":
      return {
        ...state,
        error: true,
        SingleRecord: null,
        message: "Record Fetch Error",
        Loader: false,
      };

    case "FetchRecord_SUCCESS":
      return {
        ...state,
        [action.recordName]: action.payload.Record && action.payload.Record,
        Loader: false,
        fetchSuccess: true,
        fetchDataMessage: action.payload,
        FetchingSpinner: false,
        studentSuccess: false,
      };

    case "FetchRecord_FAILURE":
      return {
        ...state,
        error: true,
        message: "Record Fetch Error",
        Loader: false,
        fetchSuccess: false,
        FetchingSpinner: false,
      };
    case "AddRecordPagination_SUCCESS":
      let AddRecordPagination = addItemInRecord(
        state[action.recordName]["data"],
        action.payload.Record
      );

      return {
        ...state,
        [action.recordName]: {
          ...state[action.recordName],
          data: [...AddRecordPagination],
        },
        Loader: false,
        success: true,
        FetchingSpinner: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Successfully",
        savingSpinner: false,
      };

    case "AddRecord_SUCCESS":
      let Record = addItemInRecord(
        state[action.recordName]["tableData"],
        action.payload.Record.tableData[0]
      );
      let totaladd = state[action.recordName]["total"];
      return {
        ...state,
        [action.recordName]: {
          ...state[action.recordName],
          tableData: [...Record],
          total: totaladd + 1,
        },
        Loader: false,
        success: true,
        FetchingSpinner: false,
        studentSuccess: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Added Successfully",
        savingSpinner: false,
      };

    case "AddRecord_FAILURE":
      console.log("======= action ====", action);
      return {
        ...state,
        Loader: false,
        error: true,
        FetchingSpinner: false,
        studentSuccess: false,
        message: action.payload ? action.payload : "Record Added Fail",
        savingSpinner: false,
      };
    case "AlreadyExist_FAILURE":
      return {
        ...state,
        Loader: false,
        error: true,
        message: action.payload,
        savingSpinner: false,
      };
    case "DeleteRecordPagination_SUCCESS":
      let deleteRecordPagination = recordAfterDeleted(
        state[action.recordName]["data"],
        action.payload.Record
      );

      return {
        ...state,
        [action.recordName]: {
          ...state[action.recordName],
          data: [...deleteRecordPagination],
        },
        Loader: false,
        success: true,
        message: action.payload.message
          ? action.payload.message
          : "Record Delete Successfully",
        savingSpinner: false,
      };
    case "EditRecordPagination_SUCCESS":
      let EditRecordPagination = recordAfterUpdate(
        state[action.recordName]["data"],
        action.payload.Record
      );
      return {
        ...state,
        [action.recordName]: {
          ...state[action.recordName],
          data: [...EditRecordPagination],
        },
        Loader: false,
        success: true,
        FetchingSpinner: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Successfully",
        savingSpinner: false,
      };

    case "EditRecord_SUCCESS":
      console.log("====editRecord", state[action.recordName]);
      let EditRecord = recordAfterUpdate(
        state[action.recordName]["tableData"],
        action.payload.Record
      );

      return {
        ...state,
        [action.recordName]: {
          ...state[action.recordName],
          tableData: [...EditRecord],
        },
        Loader: false,
        success: true,
        FetchingSpinner: false,
        studentSuccess: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Successfully",
        savingSpinner: false,
      };

    case "EditRecord_FAILURE":
      return {
        ...state,
        Loader: false,
        error: true,
        FetchingSpinner: false,
        studentSuccess: false,
        deleteMessage: action.payload,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Fail",
        savingSpinner: false,
      };

    case "DeleteRecord_SUCCESS":
      console.log("====deleteRecord", state[action.recordName]);
      let deleteRecord = recordAfterDeleted(
        state[action.recordName]["tableData"],
        action.payload.Record
      );
      let totalDel = state[action.recordName]["total"];
      console.log("====deleteRecord", deleteRecord);
      return {
        ...state,
        [action.recordName]: {
          ...state[action.recordName],
          tableData: [...deleteRecord],
          total: totalDel - 1,
        },
        Loader: false,
        success: true,
        studentSuccess: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Delete Successfully",
        savingSpinner: false,
      };

    case "DeleteRecord_ERROR":
      return {
        ...state,
        Loader: false,
        DeleteError: true,

        message: action.payload,
        savingSpinner: false,
      };

    case "DeleteRecord_FAILURE":
      return {
        ...state,
        Loader: false,
        DeleteError: true,
        studentSuccess: false,
        deleteMessage: action.payload,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Fail",
        savingSpinner: false,
      };

    case "FetchTableRecord_SUCCESS":
      return {
        ...state,
        chartRecord: action.payload.Record && [...action.payload.Record.cards],
        [action.recordName]: action.payload.Record && action.payload.Record,
        Loader: false,
        fetchSuccess: true,
        FetchingSpinner: false,
        studentSuccess: false,
      };

    case "FetchTableRecord_FAILURE":
      return {
        ...state,
        error: true,
        message: "Record Fetch Error",
        Loader: false,
        fetchSuccess: false,
        FetchingSpinner: false,
        studentSuccess: false,
      };

    case "AddTableRecord_SUCCESS":
      let RecordTable = addItemInRecord(
        state[action.recordName]["tableData"],
        action.payload.Record.tableData[0]
      );
      let oldAddtatotal = state[action.recordName]["total"];
      return {
        ...state,
        [action.recordName]: {
          ...state[action.recordName],
          tableData: [...RecordTable],
          total: oldAddtatotal + 1,
        },

        chartRecord: action.payload.Record && [...action.payload.Record.cards],
        Loader: false,
        success: true,
        FetchingSpinner: false,
        studentSuccess: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Added Successfully",
        savingSpinner: false,
      };

    case "AddTableRecord_FAILURE":
      console.log("======= action ====", action);
      return {
        ...state,
        Loader: false,
        error: true,
        FetchingSpinner: false,
        studentSuccess: false,
        message: action.payload ? action.payload : "Record Added Fail",
        savingSpinner: false,
      };

    case "EditTableRecord_SUCCESS":
      console.log("====EditRecord", state[action.recordName]);
      let EditTableRecord = recordAfterUpdate(
        state[action.recordName]["tableData"],
        action.payload.Record.tableData[0]
      );
      return {
        ...state,
        [action.recordName]: {
          ...state[action.recordName],
          tableData: [...EditTableRecord],
        },
        chartRecord: action.payload.Record && [...action.payload.Record.cards],
        Loader: false,
        success: true,
        FetchingSpinner: false,
        studentSuccess: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Successfully",
        savingSpinner: false,
      };

    case "EditTableRecord_FAILURE":
      return {
        ...state,
        Loader: false,
        error: true,
        FetchingSpinner: false,
        studentSuccess: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Fail",
        savingSpinner: false,
      };

    case "DeleteTableRecord_SUCCESS":
      let deleteTableRecord = recordAfterDeleted(
        state[action.recordName]["tableData"],
        action.payload.Record.deletedRecordId
      );
      console.log("====deleteTableRecord", [...deleteTableRecord]);
      let oldDeltatotal = state[action.recordName]["total"];
      return {
        ...state,
        chartRecord: action.payload.Record && [...action.payload.Record.cards],
        [action.recordName]: {
          ...state[action.recordName],
          tableData: [...deleteTableRecord],
          total: oldDeltatotal - 1,
        },
        Loader: false,
        success: true,
        studentSuccess: false,
        message: action.payload.message
          ? action.payload.message
          : "Record Delete Successfully",
        savingSpinner: false,
      };
case "AddCustom_SUCCESS" :{
  return {
    ...state,
    addCustom:true,
    customAddMessage:'Student added successufully',
    Loader:false,
    FetchingSpinner:false
  }
}

      case "DeleteTableRecord_FAILURE":
        console.log("===here1===");
        return {
          ...state,
          Loader: false,
          DeleteError: true,
          message: action.payload,
          studentSuccess: false,
          savingSpinner: false,
        };
    case "DeleteTableRecord_ERROR":
      return {
        ...state,
        Loader: false,
        DeleteError: true,
        message: action.payload,
        studentSuccess: false,
        savingSpinner: false,
      };
    case "ResetAll_State":
      return {
        ...state,
        success: false,
        error: false,
        DeleteError: false,
        message: "",
        Loader: false,
        studentSuccess: false,
        savingSpinner: false,
        fetchSuccess: false,
        bulkImportSuccess: false,
        timeListMessage: "",
        timeListTrue: false,
        addCustom:false,
      };

    default:
      return state;
  }
};
